import MaskedInput from "react-text-mask"
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const CurrencyInput = (props: any) => {
  const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    requireDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 6, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...props.maskOptions,
  })

  return (
    <MaskedInput className="form-control" mask={currencyMask} {...props} />
  )

}

export default CurrencyInput;
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../../@enums/index";
import { optionsCivilState, optionsSexUser } from "../../../../@options";
import 'dotenv/config';
import { useHistory } from "react-router-dom";

//services
import { listarCargos, listarProfissoes, listarFormasDePagamento, listarOpcionais, listarCores } from '../../../../services/locadorasParams.service';
import { getStatesList } from '../../../../services/signCar.service';
import { savePedido } from "../../../../services/submitForms.service";

//components
import ReactInputMask from "react-input-mask";
import Select from 'react-select';
import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import { Error } from "../../../../components/Error";
import CurrencyInput from "../../../../components/CurrencyInput";
import { toast } from "react-toastify";
import { cpfValido } from "../../../../helpers";

export const FormLocalizaMeoo = (props: any): JSX.Element => {
  const { nextStep, locadorasParams, signData,
    loading, setLoading, user, setRequestId, handleClickBack } = props;
  const [showFormPessoaJuridica, setShowFormPessoaJuridica] = useState<boolean>(signData.contractType === '1' ? false : true);

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [optionStates, setOptionStates] = useState([]);
  const [optionProfissao, setOptionProfissao] = useState([]);
  const [optionCargo, setOptionCargo] = useState([]);
  const [listOptionals, setListOptionals] = useState([]);
  const [optionalsSelected, setOptionalsSelected] = useState([]);
  const [colors, setColors] = useState([]);

  const validationSchema = Yup.object({
    corCarro: Yup.object(),
    opcionais: Yup.array(),

    name: !showFormPessoaJuridica ? Yup.string().required('O nome é obrigatório') : Yup.string().required('O nome do sócio é obrigatório'),
    email: !showFormPessoaJuridica && Yup.string().required('O email é obrigatório'),
    celular:  !showFormPessoaJuridica && Yup.string().required('O celular é obrigatório'),
    dataNascimento: !showFormPessoaJuridica && Yup.string().required('A data de nascimento é obrigatória'),
    logradouro: Yup.string().required('O logradouro é obrigatório'),
    numero: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string().required('O bairro é obrigatório'),
    cidade: Yup.string().required('A cidade é obrigatória'),
    estado: Yup.object().required('O estado é obrigatório'),

    razaoSocial: showFormPessoaJuridica ? Yup.string().required('A razão social é obrigatória') : Yup.string(),
    cnpj: showFormPessoaJuridica ? Yup.string().min(18, "Muito curto").max(18, "Muito longo").required('O CNPJ é obrigatório') : Yup.string(),
    emailSocio: showFormPessoaJuridica ? Yup.string().email('E-mail inválido').required('O e-mail do sócio é obrigatório') : Yup.string(),
    celularSocio: showFormPessoaJuridica ? Yup.string().required('O celular do sócio é obrigatório') : Yup.string()

  }).required();

  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
  const history = useHistory()

  const onSubmit = (formData: any) => {
    setLoadingSubmit(true);
    let objSubmit = {
      pedido: {
        locadora_id: signData.locadoraId,
        veiculo_id: signData.selectedCar.veiculo_id,
        tempo_contrato: signData.selectedCar.contractTime,
        franquia_km: signData.franchise,
        forma_pagamento: signData.payWay,
        opcionais: optionalsSelected,
        tipo_contrato: signData.contractType,
        cor_id: formData.corCarro?.value ?? ""
      },
      cliente: {
        lead_id: signData.lead_id,

        razao_social: showFormPessoaJuridica ? formData.razaoSocial : null,
        num_cnpj: showFormPessoaJuridica ? formData.cnpj : null,
        email: showFormPessoaJuridica ? formData.emailSocio : formData.email,
        num_celular: showFormPessoaJuridica ? formData.celularSocio : formData.celular,
        
        nome_completo: formData.name ?? null,
        num_cpf: user.cpf ?? null,

        data_nascimento: formData.dataNascimento ?? null,
        estado_civil_id: formData.estadoCivil?.value ?? null,
        sexo_id: formData.sexo?.value ?? null,
        nacionalidade: formData.nacionalidade ?? null,
        num_rg: formData.rg ?? null,
        data_emissao_rg: formData.dataEmissaoRg ?? null,
        orgao_emissor_rg: formData.orgaoEmissor ?? null,
        num_cnh: formData.cnh ?? null,
        data_emissao_cnh: formData.dataEmissaoCnh ?? null,
        data_validade_cnh: formData.dataValidadeCnh ?? null,
        categoria_profissional_id: formData.profissao?.value ?? null,
        cargo_id: formData.cargo?.value ?? null,
        data_admissao: formData.dataAdmissao ?? null,
        renda: formData.renda ?? null,
        endereco: {
          num_cep: user.cep,
          logradouro: formData.logradouro,
          numero: formData.numero,
          complemento: formData.complemento,
          bairro: formData.bairro,
          cidade: formData.cidade,
          sigla_estado: formData.estado.value
        }
      }
    }

    savePedido(objSubmit).then((response) => {
      setRequestId(response.order)
      const tagSemPararStep = process.env.REACT_APP_SHOW_TAGSEMPARAR_STEP;
      nextStep((parseInt(tagSemPararStep) === 1 ?
        SIGN_CAR_STEPS.tagSemParar :
        SIGN_CAR_STEPS.successSign));
        history.push(`/sign-car/sem-parar`);
      setLoadingSubmit(false);
      
    }).catch((err) => {
      setLoadingSubmit(false);
      toast.error(err.response.data);
    })
  }

  useEffect(() => {
    // setOptionFormaPagamento(listarFormasDePagamento(locadorasParams, signData.locadoraId));
    setOptionProfissao(listarProfissoes(locadorasParams, signData.locadoraId));
    setOptionCargo(listarCargos(locadorasParams, signData.locadoraId));
    setListOptionals(listarOpcionais(locadorasParams, signData.locadoraId));
    setColors(listarCores(JSON.parse(signData.selectedCar.json_cores)));
    getStatesList().then((response) => {
      let userStateSelect = ""
      setOptionStates(response.map((estado: any) => {
        if (estado.sigla === user.address.estado) {
          userStateSelect = estado.nome + ' - ' + estado.sigla;
        }
        return {
          label: estado.nome + ' - ' + estado.sigla,
          value: estado.sigla,
        }
      }));
      setValue('estado', { value: user.address.estado, label: userStateSelect })
      setLoading(false);
    });
  }, []);

  if (!loading) {
    return (
      <div className="mt-5">
        <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
          {
            (colors.length > 0 || listOptionals.length > 0) &&
            <h5>Personalize seu contrato</h5>
          }

          <Row>
            {
              colors.length > 0 &&
              <Col md={12} className="mb-2">
                <Controller
                  name="corCarro"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Group>
                      <Form.Label>Cores:</Form.Label>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={colors}
                        placeholder="Selecione..."
                      />
                    </Form.Group>
                  )}
                />
              </Col>
            }

            {
              listOptionals.length > 0 &&
              <Col xs={12} className="mb-2">
                <Form.Label>Opcionais:</Form.Label><br/>
                {
                  listOptionals.map((optional, index) => (
                    <Form.Check
                      key={index}
                      inline
                      label={optional.label}
                      type="checkbox"
                      id={optional.value}
                      onChange={() => {
                        if(!optionalsSelected.includes(optional.value)) {
                          setOptionalsSelected([...optionalsSelected, optional.value])
                        } else {
                          let arr = optionalsSelected;
                          setOptionalsSelected(arr.filter(x => x !== optional.value))
                        }
                      }}
                    />
                  ))
                }
              </Col>
            }
          </Row>

          <h5 className="mt-4">Agora precisamos dos últimos detalhes da sua assinatura</h5>
          {
            showFormPessoaJuridica ?
              (
                <>
                  <Row>
                    <Col md={6} className="mb-2">
                      <Form.Group>
                        <Form.Label>Razão Social:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Razão Social"
                          {...register("razaoSocial", { required: true })}/>
                        {errors.razaoSocial ? (<div className="w-100"> <Error>{errors.razaoSocial?.message}</Error> </div>) : ""}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Controller
                        name="cnpj"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field }) => (
                          <ReactInputMask mask="99.999.999/9999-99"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Form.Group>
                                <Form.Label>CNPJ:</Form.Label>
                                <Form.Control
                                  {...inputProps}
                                  placeholder="Digite o CNPJ"/>
                              </Form.Group>
                            )}
                          </ReactInputMask>
                        )}
                      />
                      {errors.cnpj ? (<div className="w-100"> <Error>{errors.cnpj?.message}</Error> </div>) : ""}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-2">
                      <Form.Group>
                        <Form.Label>E-mail do Sócio:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="E-mail"
                          {...register("emailSocio", { required: true })}/>
                        {errors.emailSocio ? (<div className="w-100"> <Error>{errors.emailSocio?.message}</Error> </div>) : ""}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Controller
                        name="celularSocio"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field }) => (
                          <ReactInputMask mask="(99) 99999-9999"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Form.Group>
                                <Form.Label>Celular do Sócio:</Form.Label>
                                <Form.Control
                                  {...inputProps}
                                  placeholder="Digite o celular"/>
                              </Form.Group>
                            )}
                          </ReactInputMask>
                        )}
                      />
                      {errors.celularSocio ? (<div className="w-100"> <Error>{errors.celularSocio?.message}</Error> </div>) : ""}
                    </Col>
                    <Col xs={6} className="mb-2">
                      <Form.Group>
                        <Form.Label>Nome do sócio:</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Digite seu nome completo"
                          defaultValue=""
                          {...register("name", { required: true })} />
                        {errors.name ? (<div className="w-100"> <Error>{errors.name?.message}</Error> </div>) : ""}
                      </Form.Group>
                    </Col>

                    <Col xs={6} className="mb-2">
                      <Controller
                        name="cpf"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field }) => (
                          <ReactInputMask mask="999.999.999-99"
                            value={user.cpf}
                            readOnly={true}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Form.Group>
                                <Form.Label>CPF do sócio:</Form.Label>
                                <Form.Control
                                  defaultValue=""
                                  {...inputProps}
                                  readOnly
                                  placeholder="Digite seu CPF" />
                              </Form.Group>
                            )}
                          </ReactInputMask>
                        )}
                      />
                      {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
                    </Col>
                  </Row>
                </>
              ) :
              (
                <>
                  <Row>
                    <Col xs={12} className="mb-2">
                      <Form.Group>
                        <Form.Label>Nome Completo:</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Digite seu nome completo"
                          defaultValue={user.name}
                          {...register("name", { required: true })} />
                        {errors.name ? (<div className="w-100"> <Error>{errors.name?.message}</Error> </div>) : ""}
                      </Form.Group>
                    </Col>

                    <Col xs={12} className="mb-2">
                      <Controller
                        name="cpf"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field }) => (
                          <ReactInputMask mask="999.999.999-99"
                            value={user.cpf}
                            readOnly={true}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Form.Group>
                                <Form.Label>CPF:</Form.Label>
                                <Form.Control
                                  defaultValue=""
                                  {...inputProps}
                                  readOnly
                                  placeholder="Digite seu CPF" />
                              </Form.Group>
                            )}
                          </ReactInputMask>
                        )}
                      />
                      {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
                    </Col>

                    <Col xs={12} className="mb-2">
                      <Form.Group>
                        <Form.Label>Seu melhor e-mail:</Form.Label>
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="Digite seu melhor e-mail"
                          defaultValue={user.email}
                          {...register("email", { required: true })} />
                        {errors.email ? (<div className="w-100"> <Error>{errors.email?.message}</Error> </div>) : ""}
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-2">
                      <Controller
                        name="celular"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={user.celular}
                        render={({ field }) => (
                          <ReactInputMask mask="(99) 99999-9999"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Form.Group>
                                <Form.Label>Celular com Whatsapp:</Form.Label>
                                <Form.Control
                                  defaultValue={user.celular}
                                  {...inputProps}
                                  placeholder="Digite seu celular" />
                              </Form.Group>
                            )}
                          </ReactInputMask>
                        )}
                      />
                      {errors.celular ? (<div className="w-100"> <Error>{errors.celular?.message}</Error> </div>) : ""}
                    </Col>

                    <Col md={6} className="mb-2">
                      <Form.Group>
                        <Form.Label>Data de Nascimento:</Form.Label>
                        <Form.Control 
                          type="date"
                          min="1900-01-01"
                          max="3000-12-31"
                          {...register("dataNascimento", { required: true })}
                          placeholder="Digite a sua data de nascimento"/>
                      </Form.Group>
                      {errors.dataNascimento ? (<div className="w-100"> <Error>{errors.dataNascimento?.message}</Error> </div>) : ""}
                    </Col>
                  </Row>
                </>
              )
          }

          <Row>
            {/* endereço */}
            <Col md={6} className="mb-2">
              <Form.Group>
                <Form.Label>Logradouro:</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={user && user.address.logradouro ? user.address.logradouro : ""}
                  placeholder="Logradouro"
                  {...register("logradouro", { required: true })}/>
                {errors.logradouro ? (<div className="w-100"> <Error>{errors.logradouro?.message}</Error> </div>) : ""}
              </Form.Group>
            </Col>

            <Col md={3} className="mb-2">
              <Form.Group>
                <Form.Label>Número:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nº"
                  {...register("numero")}/>
              </Form.Group>
            </Col>

            <Col md={3} className="mb-2">
              <Form.Group>
                <Form.Label>Bairro:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o bairro"
                  defaultValue={user && user.address.bairro ? user.address.bairro : ""}
                  {...register("bairro", { required: true })} />
                {errors.bairro ? (<div className="w-100"> <Error>{errors.bairro?.message}</Error> </div>) : ""}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-2">
              <Form.Group>
                <Form.Label>Complemento:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Complemento"
                  {...register("complemento")} />
              </Form.Group>
            </Col>


            <Col md={3} className="mb-2">
              <Form.Group>
                <Form.Label>Cidade:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite a cidade"
                  defaultValue={user && user.address.cidade ? user.address.cidade : ""}
                  {...register("cidade", { required: true })}/>
                {errors.cidade ? (<div className="w-100"> <Error>{errors.cidade?.message}</Error> </div>) : ""}
              </Form.Group>
            </Col>

            <Col md={3} className="mb-2">
              <Controller
                name="estado"
                control={control}
                rules={{ required: true }}
                defaultValue="ES"
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Estado:</Form.Label>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={optionStates}
                      placeholder="Selecione..."
                    />
                  </Form.Group>
                )}
              />
              {errors.estado ? (<div className="w-100"> <Error>{errors.estado?.message}</Error> </div>) : ""}
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mt-5 mb-5 d-grid gap-2 mx-auto">
              {
                loadingSubmit ? (
                  <div className="d-flex justify-content-center mb-3">
                    <Spinner animation="border" variant="primary" className="spinner-button" />
                  </div>
                ) : (
                  <Button type="submit" className="w-100 mb-3">Concluir</Button>
                )
              }
              <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
            </Col>
          </Row>

        </Form>
      </div>
    )
  } else {
    return null;
  }
}
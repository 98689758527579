import axios from 'axios';
import api from './api';
import 'dotenv/config';

export const budget = async (locadoraId: number, veiculoId: string, 
  franquiaKm: number, contractTime: number, customerName: string,
  customerPhone: string, customerEmail: string, leadId: number,  contractType: number) => {
  const params = {
    locadora_id: locadoraId,
    veiculo_id: veiculoId,
    franquia_km: franquiaKm,
    tempo_contrato: contractTime,
    nome_cliente: customerName,
    telefone_cliente: customerPhone,
    email_cliente: customerEmail,
    lead_id: leadId,
    tipo_contrato_id: contractType
  };
  const { data } = await api.post(`out/cotacao`, {...params});
  return data;
}

export const verifyRegister = async () => {
  const { data } = await axios.get(`categorias.json`);
  return data;
}

export const getModelsList = async (locadoraId: number, numCep: string, categoriaId: number, tipoContratoId: number) => {
  const { data } = await api.get(`out/veiculos?locadora_id=${locadoraId}&cep_localidade=${numCep}&categoria_id=${categoriaId}&tipo_contrato_id=${tipoContratoId}`);
  return data;
}

export const getStatesList = async () => {
  const { data } = await api.get(`out/estados`);
  return data;
}

export const getModelsListPlans = async (locadoraId: number, veiculoId: any[], franquiaKm: number, tipoContratoId: number) => {
  const params = {
    locadora_id: locadoraId,
    veiculo_id: veiculoId,
    franquia_km: franquiaKm,
    tipo_contrato_id: tipoContratoId
  };
  const { data } = await api.get(`out/veiculos/planos`, {params});
  return data;
}

export const getUserInfoSemParar = async (token: string) => {
  const headers = {
    'Content-Type' : 'application/json',
    'Authorization': `Bearer ${token}`,
    'x-api-key': process.env.REACT_APP_SEMPARAR_API_KEY,
  }
  const { data } = await axios.get(process.env.REACT_APP_SEMPARAR_URL, { headers: headers });
  return data;
}
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../../@enums/index";
import { optionsCivilState, optionsSexUser } from "../../../../@options";
import 'dotenv/config';

//components
import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import Select from 'react-select';
import { Error } from "../../../../components/Error";
import CurrencyInput from "../../../../components/CurrencyInput";

//services
import { getStatesList } from "../../../../services/signCar.service";
import { savePedido } from "../../../../services/submitForms.service";
import { listarOpcionais, listarCores } from "../../../../services/locadorasParams.service";
import { toast } from "react-toastify";
import { cpfValido } from "../../../../helpers";
import ReactInputMask from "react-input-mask";
import { useHistory } from "react-router-dom";

const validationSchema = Yup.object({
  dataNascimento: Yup.string().required('A data de nascimento é obrigatória'),
  estadoCivil: Yup.object().required('O estado civil é obrigatório'),
  sexo: Yup.object().required('O sexo é obrigatório'),
  nomeMae: Yup.string().required('O nome da mãe é obrigatória'),
  profissao: Yup.string().required('A profissão é obrigatória'),
  nomeEmpresa: Yup.string().required('O nome da empresa é obrigatório'),
  renda: Yup.string().test("test-value","Insira somente números",function(value){
    value=value.replace('R$',"").trim()
    return true
  }).required('A renda é obrigatória'),
  logradouro: Yup.string().required('O logradouro é obrigatório'),
  numero: Yup.string(),
  complemento: Yup.string(),
  bairro: Yup.string().required('O bairro é obrigatório'),
  cidade: Yup.string().required('A cidade é obrigatória'),
  estado: Yup.object().required('O estado é obrigatório'),
  cnhFile: Yup.array().required('O upload da CNH é obrigatório'),
  comprovanteEnderecoFile: Yup.array().required('O upload do comprovante de endereço é obrigatório'),
  comprovanteRendaFile: Yup.array().required('O upload do comprovante de renda é obrigatório'),
}).required();

export const FormByecarOriginals = (props: any): JSX.Element => {
  const { nextStep, locadorasParams, signData, 
    loading, setLoading, user, setRequestId, handleClickBack } = props;
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [listOptionals, setListOptionals] = useState([]);
  const [optionalsSelected, setOptionalsSelected] = useState([]);
  const [colors, setColors] = useState([]);
  const [optionStates, setOptionStates] = useState([]);
  
  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
  const history = useHistory()

  const onSubmit = (formData: any) => {
    setLoadingSubmit(true);
    let objSubmit = {
      pedido: {
        locadora_id: signData.locadoraId,
        veiculo_id: signData.selectedCar.veiculo_id,
        tempo_contrato: signData.selectedCar.contractTime,
        franquia_km: signData.franchise,
        forma_pagamento: signData.payWay,
        tipo_contrato: signData.contractType,
        opcionais: optionalsSelected,
        cor_id: formData.corCarro.value ?? "",
      },
      cliente: {
        lead_id: signData.lead_id,
        nome_completo: user.name,
        num_cpf: user.cpf,
        email: user.email,
        num_celular: user.celular,
        data_nascimento: formData.dataNascimento,
        estado_civil_id: formData.estadoCivil.value,
        sexo_id: formData.sexo.value,
        nome_mae: formData.nomeMae,
        nome_profissao: formData.profissao,
        nome_empresa: formData.nomeEmpresa,
        renda: formData.renda,
        endereco: {
          num_cep: user.cep,
          logradouro: formData.logradouro,
          numero: formData.numero,
          complemento: formData.complemento,
          bairro: formData.bairro,
          cidade: formData.cidade,
          sigla_estado: formData.estado.value
        },
      },
      url_cnh: formData.cnhFile[0],
      url_comprovante_renda: formData.comprovanteRendaFile[0],
      url_comprovante_endereco: formData.comprovanteEnderecoFile[0],
    }
    
    savePedido(objSubmit).then((response) => {
      setRequestId(response.order)
      const tagSemPararStep = process.env.REACT_APP_SHOW_TAGSEMPARAR_STEP;
      nextStep((parseInt(tagSemPararStep) === 1 ? 
        SIGN_CAR_STEPS.tagSemParar : 
        SIGN_CAR_STEPS.successSign));
      setLoadingSubmit(false);
      history.push(`/sign-car/sem-parar`)
    }).catch((err) => {
      setLoadingSubmit(false);
      toast.error(err.response.data)
    })
  }

  useEffect(() => {
    setListOptionals(listarOpcionais(locadorasParams, signData.locadoraId));
    setColors(listarCores(JSON.parse(signData.selectedCar.json_cores)));
    getStatesList().then((response) => {
      let userStateSelect = ""
      setOptionStates(response.map((estado: any) => {
        if (estado.sigla === user.address.estado) {
          userStateSelect = estado.nome + ' - ' + estado.sigla;
        }
        return {
          label: estado.nome + ' - ' + estado.sigla,
          value: estado.sigla,
        }
      }));
      setValue('estado', { value: user.address.estado, label: userStateSelect })
      setLoading(false);
    });
  },[]);

  return (
    <div className={loading ? 'd-none' : 'mt-5'}>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        {
          colors.length > 0 || listOptionals.length > 0 &&
          <h5>Personalize seu contrato</h5>
        }
        <Row>
          {
            colors.length > 0 &&
            <Col md={12} className="mb-2">
              <Controller
                name="corCarro"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Cores:</Form.Label>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={colors}
                      placeholder="Selecione..."
                      defaultValue=""
                      />
                  </Form.Group>
                )}
              />
            </Col>
          }

          {
            listOptionals.length > 0 &&
            <Col xs={12} className="mb-2">
              <Form.Label>Opcionais:</Form.Label><br/>
              {
                listOptionals.map((optional, index) => (
                  <Form.Check
                    key={index}
                    inline
                    label={optional.label}
                    type="checkbox"
                    id={optional.value}
                    onChange={() => {
                      if(!optionalsSelected.includes(optional.value)) {
                        setOptionalsSelected([...optionalsSelected, optional.value])
                      } else {
                        let arr = optionalsSelected;
                        setOptionalsSelected(arr.filter(x => x !== optional.value))
                      }
                    }}
                  />
                ))
              }
            </Col>
          }            
        </Row>

        <h5 className="mt-4">Agora precisamos dos últimos detalhes da sua assinatura</h5>
        <Row>
        <Col md={6} className="mb-2">
            <Controller
              name="cpf"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <ReactInputMask mask="999.999.999-99"
                  value={user.cpf}
                  readOnly={true}
                  onChange={field.onChange}
                >
                  {(inputProps: any) => (
                    <Form.Group>
                      <Form.Label>CPF:</Form.Label>
                      <Form.Control
                        defaultValue=""
                        {...inputProps}
                        readOnly
                        placeholder="Digite seu CPF" />
                    </Form.Group>
                  )}
                </ReactInputMask>
              )}
            />
            {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
          </Col>
          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Data de Nascimento:</Form.Label>
              <Form.Control 
                type="date"
                min="1900-01-01"
                max="3000-12-31"
                {...register("dataNascimento", { required: true })}
                placeholder="Digite a sua data de nascimento"/>
            </Form.Group>
            {errors.dataNascimento ? (<div className="w-100"> <Error>{errors.dataNascimento?.message}</Error> </div>) : ""}
          </Col>

          <Col md={6} className="mb-2">
            <Controller
              name="estadoCivil"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Estado Civil:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    options={optionsCivilState}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.estadoCivil ? (<div className="w-100"> <Error>{errors.estadoCivil?.message}</Error> </div>) : ""}
          </Col>

          <Col md={6} className="mb-2">
            <Controller
              name="sexo"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Sexo:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    options={optionsSexUser}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.sexo ? (<div className="w-100"> <Error>{errors.sexo?.message}</Error> </div>) : ""}
          </Col>

          <Col md={6} className="mb-2">
          <Form.Group>
            <Form.Label>Nome da mãe:</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Digite o nome da sua mãe"
              {...register("nomeMae", { required: true })}/>
              {errors.nomeMae ? (<div className="w-100"> <Error>{errors.nomeMae?.message}</Error> </div>) : ""}
          </Form.Group>
        </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Profissão:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite sua profissão"
                {...register("profissao", { required: true })}/>
                {errors.profissao ? (<div className="w-100"> <Error>{errors.profissao?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Renda:</Form.Label>
              <CurrencyInput {...register("renda", { required: true })} placeholder="R$" onChange={(e: any) => setValue("renda", e.target.value)} />
              {errors.renda ? (<div className="w-100"> <Error>{errors.renda?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Nome da Empresa:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite o nome da empresa"
                {...register("nomeEmpresa", { required: true })}/>
                {errors.nomeEmpresa ? (<div className="w-100"> <Error>{errors.nomeEmpresa?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={9} className="mb-2">
            <Form.Group>
              <Form.Label>Logradouro:</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Logradouro"
                defaultValue={user && user.address.logradouro ? user.address.logradouro : ""}
                {...register("logradouro", { required: true })}/>
                {errors.logradouro ? (<div className="w-100"> <Error>{errors.logradouro?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={3} className="mb-2">
            <Form.Group>
              <Form.Label>Número:</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Nº"
                {...register("numero")}/>
            </Form.Group>
          </Col>

          <Col xs={12} className="mb-2">
            <Form.Group>
              <Form.Label>Complemento:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Complemento"
                {...register("complemento")}/>
            </Form.Group>
          </Col>

          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>Bairro:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite o bairro"
                defaultValue={user && user.address.bairro ? user.address.bairro : ""}
                {...register("bairro", { required: true })}/>
                {errors.bairro ? (<div className="w-100"> <Error>{errors.bairro?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>Cidade:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite a cidade"
                defaultValue={user && user.address.cidade ? user.address.cidade : ""}
                {...register("cidade", { required: true })}/>
                {errors.cidade ? (<div className="w-100"> <Error>{errors.cidade?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={4} className="mb-2">
            <Controller
              name="estado"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Estado:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    options={optionStates}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.estado ? (<div className="w-100"> <Error>{errors.estado?.message}</Error> </div>) : ""}
          </Col>
        </Row>

        <h5 className="mt-5">Upload de Arquivos</h5>
        <hr style={{color: 'white'}}/>
        <Row>
          <Col lg={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>CNH</Form.Label>
              <Form.Control type="file" multiple accept="image/*,.pdf"
                onChange={(e: any) => setValue('cnhFile', [e.currentTarget.files])}/>
                {errors.cnhFile ? (<div className="w-100"> <Error>{errors.cnhFile?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col lg={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                Comprovante de Endereço<br/>
                <small><i>Conta de consumo de até 90 dias em nome do titular</i></small>
              </Form.Label>
              <Form.Control type="file" multiple accept="image/*,.pdf"
                onChange={(e: any) => setValue('comprovanteEnderecoFile', [e.currentTarget.files])} />
              {errors.comprovanteEnderecoFile ? (<div className="w-100"> <Error>{errors.comprovanteEnderecoFile?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col lg={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Comprovante de Renda<br/>
                <small><i>3 últimos extratos bancários ou holerites em nome do titular</i></small>

              </Form.Label>
              <Form.Control type="file" multiple accept="image/*,.pdf"
                onChange={(e: any) => setValue('comprovanteRendaFile', [e.currentTarget.files])} />
              {errors.comprovanteRendaFile ? (<div className="w-100"> <Error>{errors.comprovanteRendaFile?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={6} className="mt-5 mb-5 d-grid gap-2 mx-auto">
            {
              loadingSubmit ? (
                <div className="d-flex justify-content-center mb-3">
                  <Spinner animation="border" variant="primary" className="spinner-button" />
                </div>
              ) : (
                <Button type="submit" className="w-100 mb-3">Concluir</Button>
              )
            }
            <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
import axios from 'axios';
import api from './api';

export const getPromotionCars = async () => {
  const { data } = await api.get(`/out/veiculos/promocao`);
  return data;
}

export const getCategoriesPromotionCars = async (slug: string) => {
  const { data } = await api.get(`/out/veiculos/slide?slug_categoria=${slug}`);
  return data;
}
import { useEffect, useState } from 'react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { BsCheck2Circle } from "react-icons/bs";

//utils
import { formatReal } from '../../helpers';
import useWindowDimensions from '../../hooks/useWindowDimensions';

// Components
import { Col, Card, Badge, Button, Row } from 'react-bootstrap';
import Carousel from "react-multi-carousel";

import * as themes from '../../theme/schema.json';
type ObjectKey = keyof typeof themes.data;
const currentTheme = process.env.REACT_APP_NAME! as ObjectKey;
const colors = themes.data[currentTheme].colors;

const CustomRightArrow = (arrowProps: any) => {

  const { carouselState, children, ...restArrowProps } = arrowProps;
  return (
    <Col {...restArrowProps} style={{ position: 'absolute', color: colors.mainColors.primary, right: '22.5%', fontSize: '30px' }}>
      <FaChevronRight />
    </Col>
  );
};

const CustomLeftArrow = (arrowProps: any) => {
  const { carouselState, children, ...restArrowProps } = arrowProps;
  return (
    <Col {...restArrowProps} style={{ position: 'absolute', color: colors.mainColors.primary, left: '22.5%', fontSize: '30px' }}>
      <FaChevronLeft />
    </Col>
  );
};

export const CarouselPromotion = ({ data, handleSignCar }: any) => {
  const [centerModeOn, setCenterModeOn] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 700) {
      setCenterModeOn(true)
    } else {
      setCenterModeOn(false)
    }
  }, [width])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 560, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50
    }
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={data.length > 1 ? true : false}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      removeArrowOnDeviceType={["mobile"]}
      centerMode={centerModeOn}
      partialVisible={centerModeOn ? false : true}
      containerClass="carousel-promotion-list"
    >
      {
        data?.map((item: any, index: number) => (
          <Card key={index} style={{minHeight: "450px"}} className="d-flex justify-space-between align-items-center card-promotion text-center">
            <div className='w-100' style={{height: "10%"}}>
              <h4><b>{item.nome_veiculo}</b></h4>
            </div>
            <div className='d-flex justify-content-center align-items-center' style={{height: "60%"}}>
              <img width="250px" src={item.url_foto} alt="" />
              <div className="background-car-circle"/>
            </div>
            <div className='w-100' style={{height: "30%"}}>
              <Row>
                <Col md={6}>
                  <h4>A partir de <Badge bg="secondary">R$ {formatReal(item.preco_promocional ?? item.preco)}</Badge></h4>
                  <h6>Plano de {item.tempo_contrato} meses</h6>
                </Col>
                <Col md={6}>
                  {
                    item.prazo_entrega_int < 0 ? (
                      <span className="badge bg-success">
                        <BsCheck2Circle className="me-2" />
                        Pronta Entrega
                      </span>
                    ) : null
                  }
                  <Button className="mt-3 mb-3" size="sm" onClick={() => handleSignCar(
                    item.id, item.locadora_id, item.categoria_id, item.nome_categoria
                  )}>Quero assinar!</Button>
                </Col>
              </Row>
            </div>
            <div className='w-100' style={{height: "10%"}}>
              <small>{item.descricao_display}</small>
            </div>
          </Card>
        ))
      }

    </Carousel>
  )
}
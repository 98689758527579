import { FaExclamationTriangle } from 'react-icons/fa';

export interface LayoutProps {
  children: React.ReactNode
}

// services

export const Error = ({ children }: LayoutProps): JSX.Element => {
  return (
    <div className="errorFeedback">
      <FaExclamationTriangle style={{ marginTop: '-4px', marginRight: '4px', marginLeft: '4px' }} /> {children}
    </div>
  );
};
export const optionsCivilState: any = [
  {
    value: 1,
    label: "Solteiro(a)"
  },
  {
    value: 2,
    label: "Casado(a)"
  },
  {
    value: 3,
    label: "Viúvo(a)"
  },
  {
    value: 4,
    label: "Separado(a) Judicialmente"
  },
  {
    value: 5,
    label: "Divorciado(a)"
  },
  {
    value: 6,
    label: "Outros"
  },
];

export const optionsSexUser: any = [
  {
    value: 1,
    label: "Masculino"
  },
  {
    value: 2,
    label: "Feminino"
  },
];

export const optionsPayWay: any = [
  {
    value: 1,
    label: "Cartão de Crédito"
  },
  {
    value: 2,
    label: "Boleto"
  },
];

export const optionsYesOrNo: any = [
  {
    value: "sim",
    label: "Sim"
  },
  {
    value: "nao",
    label: "Não"
  },
];

export const optionsTypeContract: any = [
  {
    value: "pf",
    label: "Pessoa Física"
  },
  {
    value: "pj",
    label: "Pessoa Jurídica"
  },
];

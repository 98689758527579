import Form from 'react-bootstrap/Form';
import {BsInfoCircle} from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useEffect } from 'react';

const sleep = (ms: any) => new Promise(
  resolve => setTimeout(resolve, ms)
);


const CustomCheckSwitch = (props: any) => {

  const {
    bgColor,
    handleSwitch,
    contactRequested,
    contactRequestedShowMessage,
    setContactRequestedShowMessage
  } = props;

function addContactRequestParams(event: any) {
  event.preventDefault();

  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set('contact_req', '1');

  const queryString = urlParams.toString();
  const url = window.location.href.split('?')[0];
  const separator = url.includes('?') ? '&' : '?';
  const updatedUrl = `${url}${separator}${queryString}`;

  window.history.pushState(null, '', updatedUrl);
}

  const handleSwitchChange = async (event: any) => {
    handleSwitch(event);
    addContactRequestParams(event)
    const el = document.getElementById('custom-card-switch');
    await sleep(5000);
    el.style.transition = 'opacity 300ms ease-in-out';
    el.style.opacity = '0';
    setContactRequestedShowMessage(false);
  }



  return (
    <>
      <div id="custom-card-switch" className="card mt-3" style={
        contactRequested === true ? {
          background: bgColor,
          transition: `opacity 4000ms ease-in-out`,
          opacity: 0,
        } : {
          background: bgColor,
        }
      }>
        <div className="card-body">
          <Form.Check
            style={
              {color: "#011F28 !important"}
            }
            onChange={(event) => handleSwitchChange(event)}
            checked={contactRequested}
            type="switch"
            id="switch-contato-especialista"
            label={
              contactRequested === true ?
                "Em breve entraremos em contato. Continue por aqui ;)" :
                "Desejo falar com um especialista"
            }
          />
        </div>
      </div>

      {/* <div className="alert alert-success alert-dismissible fade show mt-3" role="alert">
                <strong>Continue por aqui ;)</strong> Em breve entraremos em contato.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div> */}
    </>
  );
}

export default CustomCheckSwitch;
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { SIGN_CAR_STEPS } from "../../../@enums/index";

//components
import Select from 'react-select';
import { Row, Col, Form, Button, Card, Spinner, Alert } from "react-bootstrap"
import { Error } from "../../../components/Error";

//services
import { getModelsList } from "../../../services/signCar.service";
import { FaExclamationTriangle } from "react-icons/fa";
import { BsCheck2Circle } from "react-icons/bs";
import { getCategorias, getLocadorasPorPrioridadeLocacao } from "../../../services/rentalParams.service";
import CustomCheckSwitch from "../../../components/CustomCheckSwitch";

export const ModelSelection = (props: any): JSX.Element => {
  const { nextStep, user, signData, setSignData, locadorasParams, filtersCategories, setFiltersCategories, rentalFilters, setRentalFilters, categoryDefaultValue, setCategoryDefaultValue } = props;
  const { handleSubmit, control, formState: { errors }, setValue, getValues } = useForm();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingModels, setLoadingModels] = useState<boolean>(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState<boolean>(false);
  const [modelsList, setModelsList] = useState([]);
  const [categorias, setCategorias] = useState<any[]>();
  const [customSelectStyles, setCustomSelectStyles] = useState<any>();
  const [showModels, setShowModels] = useState<boolean>(false);
  const [hasVechicleSelected, setHasVechicleSelected] = useState<boolean>(false);
  const [urlVehicleParams, setUrlVehicleParams] = useState<any>({
    vehicleId: null, rentalCompanyId: null, categoryId: null, categoryName: null
  });

  const history = useHistory();

  useEffect(() => {
    if (signData.contactRequested) {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set('contact_req', '1');

      const queryString = urlParams.toString();
      const url = window.location.href.split('?')[0];
      const separator = url.includes('?') ? '&' : '?';
      const updatedUrl = `${url}${separator}${queryString}`;

      window.history.pushState(null, '', updatedUrl);
    }
  }, []);

  const onSubmit = (data: any) => {
    const selectedModels = modelsList.filter((model: any) => model['selected'] === true);
    setSignData({ ...signData, selectedModels: selectedModels });
    handleClickNext();

    setCategoryDefaultValue(data.category)
  }

  const handleClickNext = () => {
    const next: number = SIGN_CAR_STEPS.modelQuote;
    history.push(`/sign-car/cotacao/`);
    nextStep(next);
  }

  const handleClickBack = () => {
    const prev: number = SIGN_CAR_STEPS.rentalFilter;
    history.push(`/sign-car/contrato/`);
    nextStep(prev);
  }


  const handleCategoria = (categoria: any) => {
    setValue("category", categoria);

    setCategoryDefaultValue(categoria)

    setRentalFilters({ ...filtersCategories, categoria: categoria.value });

    let locadoras = getLocadorasPorPrioridadeLocacao(locadorasParams.locadoras, rentalFilters, categoria);
    let locadoraPrioritariaId = locadoras.filter((item: any) => typeof item !== undefined).shift()

    setSignData({ ...signData, locadoraId: locadoraPrioritariaId, category: categoria.value })
    handleShowModels(locadoraPrioritariaId, categoria.value);
  }

  const handleShowModels = (locadoraPrioritariaId: number, categoria: number) => {

    setLoadingModels(true)
    const cep = user.cep.replace(/[^\d]+/g, '');
    setShowModels(false);


    getModelsList(locadoraPrioritariaId, cep, categoria, signData.contractType).then((models: any) => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('vid') !== undefined) {
        setUrlVehicleParams({
          vehicleId: params.get('vid'), rentalCompanyId: params.get('lid'),
          categoryId: params.get('cid'), categoryName: params.get('cname')
        });
      }
      models.forEach((model: any) => {
        model['selected'] = model.id === params.get('vid');
        if (model.id === params.get('vid')) {
          setHasVechicleSelected(true);
        }
      });
      setModelsList(models)
      setShowModels(true);
      setLoadingModels(false)
    }).catch((err) => {
      setShowModels(false);
      setLoadingModels(false)
    });
  }

  const handleSelectModel = (model: any) => {
    let arr = modelsList.slice(0)
    let index = arr.findIndex((x: any) => x.id === model.id);
    let modelSelected = arr.filter(x => x.id === model.id);
    modelSelected[0].selected = !model.selected;
    arr.splice(index, 1, modelSelected[0]);
    setModelsList(arr);
  }

  useEffect(() => {
    let cats = getCategorias(locadorasParams.locadoras, filtersCategories);
    setCategorias(cats);

    setLoading(false);

    setCustomSelectStyles({
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#ffffff' : 'hsl(0, 0%, 50%)',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        background: state.isDisabled ? '#808f94' : 'hsl(0, 0%, 95%)',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      })
    });
  }, []);

  useEffect(() => {
    let selected = modelsList.filter((model: any) => model['selected'] === true);
    if (modelsList.length > 0 && selected.length > 0) {
      setDisabledSubmitButton(false)
    } else {
      setDisabledSubmitButton(true)
    }
  }, [modelsList]);

  if (loading) {
    return (
      <div className="loading-container" style={{ alignItems: 'start' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }


  return (
    <div className="mt-5">
      <Col xs={12}>
        <h5>Hora de selecionar os <b>modelos</b> que você mais curte</h5>
      </Col>
      <Col xs={12} className="mb-3">
        <Controller
          name="category"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Group>
              <Form.Label>Selecione uma categoria:</Form.Label>
              <Select
                {...field}
                isSearchable={false}
                onChange={(event: any) => handleCategoria(event)}
                styles={customSelectStyles}
                options={categorias}
                placeholder="Selecione..."
                value={field.value}
              />
            </Form.Group>
          )}
        />
        {errors.category ? (<div className="w-100"> <Error>{errors.category?.message}</Error> </div>) : ""}
        {
          urlVehicleParams.categoryName ?
            <Alert className="mt-1" variant={'info'}>
              O veículo escolhido na página principal
              está disponível na categoria {urlVehicleParams.categoryName}.
            </Alert> : ''
        }
      </Col>

      {
        loadingModels &&
        <div className="text-center">
          <Spinner animation="border" variant="primary" className="litle-spinner" />
        </div>
      }

      {
        modelsList.length > 0 &&
        <>
          {
            showModels && urlVehicleParams.categoryName !== null && !hasVechicleSelected ?
              <Alert className="mt-1" variant={'info'}>
                O veículo escolhido na página principal
                não está disponível para o filtro selecionado.
                Veja outras opções abaixo.
              </Alert> : ''
          }
        </>
      }

      {
        showModels ? (<Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
          <Row>
            {
              modelsList.length > 0 ? (
                modelsList.map((model: any, index: number) => (
                  <Col key={index} xs={12} md={6} className="mb-3">
                    <Card className={model.selected ? "model-selected card-model-selection text-white" : "card-model-selection"}>
                      <Form.Check
                        key={`car-${index}`}
                        checked={model.selected}
                        type="checkbox"
                        label=""
                        onChange={() => handleSelectModel(model)}
                      />
                      <div className="model-selection-text d-flex align-items-center">
                        <img width="30%" src={model.url_foto} style={{ marginRight: '5px' }} alt="" />
                        <div>
                          {
                            model.prazo_entrega_int < 0 ? (
                              <span className="badge bg-success">
                                <BsCheck2Circle className="me-2" />
                                Pronta Entrega
                              </span>
                            ) : null
                          }
                          <p style={{ width: '150px' }}>
                            {model.nome_veiculo.length > 20
                              ? model.nome_veiculo.slice(0, 20) + '...'
                              : model.nome_veiculo}
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <div className="text-center mb-4">
                  <FaExclamationTriangle className="" style={{ fontSize: '6rem', color: '#E36262' }} />
                  <h5 className="mt-3">
                    Não temos carros disponíveis para esta categoria na sua cidade. Tente uma outra categoria.
                  </h5>
                </div>
              )
            }
          </Row>
          <Row>
            <Col xs={6} className="d-grid gap-2 mx-auto mt-5 mb-5">
              {
                modelsList.length > 0 &&
                <Button type="submit" disabled={disabledSubmitButton} className="w-100 mb-3">Avançar</Button>
              }
              <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
            </Col>
          </Row>
        </Form>
        ) : (
          <Row>
            <Col xs={6} className="d-grid gap-2 mx-auto mt-5 mb-5">
              {
                modelsList.length > 0 &&
                <Button type="submit" disabled={disabledSubmitButton} className="w-100 mb-3">Avançar</Button>
              }
              <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
            </Col>
          </Row>)

      }
    </div>
  )
}
import { Button } from "react-bootstrap"

export const SuccessSign = (): JSX.Element => {
  return (
    <div className="text-center mt-5">
      <h5 className="mt-4 mb-4"><b>Agora é com a gente!</b></h5>
      <p>Em breve retornaremos com os detalhes do seu pedido!</p>

      <Button  variant="secondary" className="mt-5 w-100" onClick={() => window.location.href = "/"}>Voltar para o site</Button>
    </div>
    
  )
}
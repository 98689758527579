import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../@enums/index";

//services
import {
  getTiposContratacao, getFormasPagamento,
  getFranquiasKm, getLocadorasPorPrioridadeLocacao
} from '../../../services/rentalParams.service';

//components
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap"
import Select from 'react-select';
import { Error } from "../../../components/Error";
import CustomCheckSwitch from "../../../components/CustomCheckSwitch";

const validationSchema = Yup.object({
  contractType: Yup.string().required("Este campo é obrigatório"),
  payWay: Yup.string().required("Este campo é obrigatório"),
  franchise: Yup.object().required("Este campo é obrigatório"),
}).required();

export const RentalFilter = (props: any): JSX.Element => {

  const { nextStep, setSignData, signData,
    locadorasParams, setFiltersCategories,
    handleSwitch, contactRequestedShowMessage, setContactRequestedShowMessage, rentalFilters, setRentalFilters,
    tiposContratacaoDisabled, setTiposContratacaoDisabled, formasPagamentoDisabled, setFormasPagamentoDisabled,
    franquiasKmDisabled, setFranquiasKmDisabled, categoriasDisabled, setCategoriasDisabled, franchiseDefaultValue, setFranchiseDefaultValue,
  } = props;

  const [tiposContratacao, setTiposContratacao] = useState<any[]>();
  const [formasPagamento, setFormasPagamento] = useState<any[]>();
  const [franquiasKm, setFranquiasKm] = useState<any[]>();

  const [customSelectStyles, setCustomSelectStyles] = useState<any>();

  const [urlVehicleParams, setUrlVehicleParams] = useState<any>({
    vehicleId: null, rentalCompanyId: null, categoryId: null, categoryName: null
  });
  const history = useHistory();

  //Controle visual dos campos


  const [loading, setLoading] = useState<boolean>(true);


  const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (signData.contactRequested) {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set('contact_req', '1');

      const queryString = urlParams.toString();
      const url = window.location.href.split('?')[0];
      const separator = url.includes('?') ? '&' : '?';
      const updatedUrl = `${url}${separator}${queryString}`;

      window.history.pushState(null, '', updatedUrl);
    }
  }, []);

  const onSubmit = (formData: any) => {
    setSignData({
      ...signData, contractType: formData.contractType,
      payWay: formData.payWay, franchise: formData.franchise.value
    })

    setFiltersCategories(rentalFilters)

    handleClickNext();
  }

  const handleClickNext = () => {
    const next: number = SIGN_CAR_STEPS.modelSelection;
    const params = new URLSearchParams(window.location.search);
    let target_url = params.get('vid') !== undefined && params.get('vid') !== null ?
      `?vid=${params.get('vid')}&lid=${params.get('lid')}&cid=${params.get('cid')}&cname=${params.get('cname')}` :
      '';

    history.push(`/sign-car/categoria/${target_url}`);

    nextStep(next);
  }

  const handleConsegueComprovarRenda = (consegueComprovarRenda: string) => {

    setRentalFilters({
      ...rentalFilters,
      consegueComprovarRenda,
      tipoContratacao: null,
      formaPagamento: null,
      franquiaKm: null
    });
    setTiposContratacaoDisabled(false);
    setValue("contractType", null);
    setValue("payWay", null);
  }

  const handleTipoContratacao = (tipoContratacaoId: any) => {

    setRentalFilters({
      ...rentalFilters,
      tipoContratacao: tipoContratacaoId,
      formaPagamento: null,
      franquiaKm: null
    });
    setFormasPagamentoDisabled(false);
    setValue("payWay", null);
  }

  const handleFormaPagamento = (formaPagamentoId: any) => {

    setRentalFilters({
      ...rentalFilters, formaPagamento: formaPagamentoId,
      franquiaKm: null
    });
    setFranquiasKmDisabled(false);
  }

  const handleFranquiaKm = (franquiaKm: any) => {
    setRentalFilters({ ...rentalFilters, franquiaKm: franquiaKm.value });
    setCategoriasDisabled(false);
    setValue("franchise", franquiaKm);
    setFranchiseDefaultValue(franquiaKm)
  }

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    if (params.get('vid') !== undefined) {
      setUrlVehicleParams({
        vehicleId: params.get('vid'), rentalCompanyId: params.get('lid'),
        categoryId: params.get('cid'), categoryName: params.get('cname')
      });
    }

    setCustomSelectStyles({
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#ffffff' : 'hsl(0, 0%, 50%)',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        background: state.isDisabled ? '#808f94' : 'hsl(0, 0%, 95%)',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      })
    });

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);

  useEffect(() => {

    let tipos = getTiposContratacao(locadorasParams.locadoras, rentalFilters);
    setTiposContratacao(tipos);

    let formas = getFormasPagamento(locadorasParams.locadoras, rentalFilters);
    setFormasPagamento(formas);

    if (rentalFilters.formaPagamento !== null) {
      let franquias = getFranquiasKm(locadorasParams.locadoras, rentalFilters);
      setFranquiasKm(franquias);
    }

  }, [rentalFilters]);

  if (loading) {
    return (
      <div className="loading-container" style={{ alignItems: 'start' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  let mokedContractTypes = [{ id: 1, name: 'Pessoa Física' }, { id: 1, name: 'Pessoa Jurídica' }]

  return (
    <div className="mt-5">
      <h5>Selecione as opções que mais combinam com você</h5>
      <Form id="my-form" onSubmit={handleSubmit(onSubmit)} className="mt-5">
        <Row>
          <Col xs={12} className="mb-3">
            <Form.Label>
              Consegue comprovar sua renda?<br />
              <em>Com holerite ou IRPF</em>
            </Form.Label><br />
            <Form.Check
              key={`comprova-1`}
              inline
              value={'sim'}
              {...register("consegueComprovarRenda", { required: true, onChange: (e) => handleConsegueComprovarRenda(e.target.value) })}
              checked={rentalFilters.consegueComprovarRenda === 'sim'}
              label="Sim"
              name="consegueComprovarRenda"
              type="radio"
            />

            <Form.Check
              key={`comprova-0`}
              inline
              value={'nao'}
              {...register("consegueComprovarRenda", { required: true, onChange: (e) => handleConsegueComprovarRenda(e.target.value) })}
              checked={rentalFilters.consegueComprovarRenda === 'nao'}
              label="Não"
              name="consegueComprovarRenda"
              type="radio"
            />
          </Col>

          <Col xs={12} className="mb-3">
            <Form.Label>Tipo de Contratação:</Form.Label><br />
            {tiposContratacao.length === 0 ?
              (<>
                {mokedContractTypes.map((variant, index) => (
                  <Form.Check
                    disabled={tiposContratacaoDisabled}
                    style={{ cursor: (tiposContratacaoDisabled ? 'not-allowed' : 'pointer') }}
                    key={index}
                    inline
                    onClick={() => handleTipoContratacao(variant.id)}
                    checked={(variant.id === 1 && rentalFilters.tipoContratacao === 1) || (variant.id === 2 && rentalFilters.tipoContratacao === 2)}
                    label={variant.name}
                    value={variant.id}
                    name="contractType"
                    type="radio"
                    {...register("contractType", { required: true })} />
                ))}
              </>)
              :
              (<>

                {tiposContratacao?.map((variant, index) => (
                  <Form.Check
                    disabled={tiposContratacaoDisabled}
                    style={{ cursor: (tiposContratacaoDisabled ? 'not-allowed' : 'pointer') }}
                    key={index}
                    inline
                    onClick={() => handleTipoContratacao(variant.id)}
                    checked={variant.id === rentalFilters.tipoContratacao}
                    label={variant.name}
                    value={variant.id}
                    name="contractType"
                    type="radio"
                    {...register("contractType", { required: true })} />
                ))}

              </>)}

          </Col>

          <Col xs={12} className="mb-3">
            <Form.Label>Forma de Pagamento:</Form.Label><br />
            {formasPagamento?.map((variant, index) => (
              <Form.Check
                key={index}
                disabled={formasPagamentoDisabled}
                style={{ cursor: (formasPagamentoDisabled ? 'not-allowed' : 'pointer') }}
                inline
                onClick={() => handleFormaPagamento(variant.id)}
                checked={(variant.id === rentalFilters.formaPagamento)}
                label={variant.name}
                value={variant.id}
                name="payWay"
                type="radio"
                {...register("payWay", { required: true })} />
            ))}
          </Col>
          <Col xs={12} className="mb-3">
            <Controller
              name="franchise"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Franquia:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    isDisabled={franquiasKmDisabled}
                    styles={customSelectStyles}
                    value={field.value}
                    onChange={(e) => handleFranquiaKm(e)}
                    options={franquiasKm}
                    placeholder="Selecione..."
                  />
                </Form.Group>
              )}
            />
            {errors.franchise ? (<div className="w-100"> <Error>{errors.franchise?.message}</Error> </div>) : ""}
          </Col>

          {
            parseInt(process.env.REACT_APP_SHOW_CONTACT_REQUESTED) === 1 &&
            contactRequestedShowMessage &&
            (
              <Col xs={12} className="mb-3">
                <CustomCheckSwitch
                  contactRequestedShowMessage={contactRequestedShowMessage}
                  setContactRequestedShowMessage={setContactRequestedShowMessage}
                  contactRequested={signData.contactRequested ?? false}
                  bgColor="#4DBCE9" handleSwitch={handleSwitch} />
              </Col>
            )
          }

          <Col xs={6} className="d-grid gap-2 mx-auto mt-5 mb-5">
            <Button type="submit" className="w-100 mb-3">Avançar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
import { ThemeProvider } from 'styled-components';
import Routes from './routes';
import GlobalStyle from './theme/globalStyle';
import { ToastContainer } from "react-toastify";
import "react-multi-carousel/lib/styles.css";

import * as themes from './theme/schema.json';
import { useEffect } from 'react';

function App() {
  type ObjectKey = keyof typeof themes.data;
  const currentTheme = process.env.REACT_APP_NAME! as ObjectKey;
  const colors = themes.data[currentTheme].colors;

  useEffect(() => {
    document.title = `Bora iniciar sua jornada de assinatura? | ${themes.data[currentTheme].name}`;
    let site_name = document.getElementById("og_site_name") as HTMLMetaElement;
    let title = document.getElementById("og_title") as HTMLMetaElement;
    let image_secure_url = document.getElementById("og_secure_url") as HTMLMetaElement;
    let image = document.getElementById("og_image") as HTMLMetaElement;
    let favicon = document.getElementById("favicon_theme") as HTMLLinkElement;
    
    site_name.content = themes.data[currentTheme].name;
    title.content = document.title;
    image_secure_url.content = themes.data[currentTheme].urlImgLogoDefault;
    image.content = themes.data[currentTheme].urlImgLogoDefault;
    favicon.href = themes.data[currentTheme].urlImgLogoDefault;
  }, []);
  
  return (
    <ThemeProvider theme={colors}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        theme={'colored'}
        closeOnClick
        pauseOnHover
      />
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
import { useContext, useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../@enums/index";
import { cpfValido } from '../../../helpers';

//components
import { Row, Col, Form, Button } from "react-bootstrap"
import ReactInputMask from 'react-input-mask';
import Select from 'react-select';
import { Error } from "../../../components/Error";
import axios from "axios";
import { toast } from "react-toastify";

import { getUserInfoSemParar } from "../../../services/signCar.service";
import UsuarioContext from "../../../context/userContext/context";
import {getTermosUso, getPoliticaPrivacidade, getColorsCurrentTheme} from "../../../services/theme.service";
import { getUtmParams } from "../../../services/utmConfig.service";


const validationSchema = Yup.object().shape({
  name: Yup.string().min(5, 'Informe seu nome completo').required("Este campo é obrigatório"),
  cpf: Yup.string().test("test-cpf", "Informe um CPF válido", function(value){
    const { path, createError } = this;
    value = value.replace(/[^\d]+/g, '');
    return (
      cpfValido(value) ||
      createError({ path, message: "Informe um CPF válido" })
    );
  })
    .required("Este campo é obrigatório"),
  email: Yup.string().email('E-mail inválido').required("Este campo é obrigatório"),
  celular: Yup.string().test("test-celular", "Informe seu celular", function(value){
    const { path, createError } = this;
    value = value.replace(/[^\d]+/g, '');
    return (
      value.length === 11 ||
      createError({ path, message: "Informe um celular válido" })
    );
    })
  .required("Este campo é obrigatório"),
  cep: Yup.string().test("test-cep", "Informe seu CEP", function(value){
    const { path, createError } = this;
    value = value.replace(/[^\d]+/g, '');
    return (
      value.length === 8 ||
      createError({ path, message: "Informe um CEP válido" })
    );
    }).required('Este campo é obrigatório'),
  utilCar: Yup.object().required("Este campo é obrigatório")
});

export const ClientInfos = (props: any): JSX.Element => {
  const colors = getColorsCurrentTheme();
  const { nextStep, setUser } = props;
  const { register, handleSubmit, control, setValue, formState: { errors }, setError } = useForm({ resolver: yupResolver(validationSchema) });
  const { user: userContext, update } = useContext(UsuarioContext);
  

  const [disableFields, setDisableFields] = useState<boolean>(false);
  
  let optionsUtilCar = [
    { value: "Trabalho em APPs", label: "Trabalho em APPs" },
    { value: "Uso familiar", label: "Uso familiar" },
    { value: "Uso corporativo", label: "Uso corporativo" }
  ];

  const fetchAddress = async (postalCode: string) => {
    const { data } = await axios.get(`https://viacep.com.br/ws/${postalCode}/json/`)
    return data;
  };
  
  const onSubmit = (user: any) => {
    const postalCodeTrimmed = user.cep.replace(/\D/g, "");
    user['address'] = {
      bairro: "",
      estado: "",
      cidade: "",
      logradouro: "",
    }

    fetchAddress(postalCodeTrimmed).then(data => {
      if (!data.erro) {
        let address = {
          bairro: data.bairro,
          estado: data.uf,
          cidade: data.localidade,
          logradouro: data.logradouro,
        }
        user['address'] = address
        setUser(user);
        nextStep(SIGN_CAR_STEPS.verifyRegister);
      } else {
        setError('cep', { type: 'custom', message: 'CEP não encontrado' });
      }
    })
    .catch(function (error: any) {
      if (error.response || error.request) {
        toast.error(error.response.data)
      }     
    });
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const urlParams = getUtmParams();
    const useParamsContext = {token: "", utmParams: {}};

    if(token !== null){
      useParamsContext.token = token;
    }
    if(urlParams !== null){
      useParamsContext.utmParams = urlParams;
    }
    
    update(useParamsContext);

    if (userContext.token || token) {
      getUserInfoSemParar(userContext.token).then((userInfo) => {
        setValue('name', userInfo.nome);
        setValue('email', userInfo.email);
        // setValue('celular', userInfo.telefone);
        setValue('cep', userInfo.cep);
        setDisableFields(true)
      }).catch((error) => {
        toast.error('Não foi possível recuperar suas informações, por favor preencha o formulário.')
        setDisableFields(false)
      })
    }
  }, [])

  return (
    <>
      <h4>Bora iniciar sua <b>jornada <br/>de assinatura?</b></h4>
      <p className="mt-5">Queremos entender qual a melhor assinatura pra você, para isso precisaremos de algumas informações. Vamos lá?!</p>
      <Form id="car-sign" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="mb-2">
            <Form.Group>
              <Form.Label>Nome Completo:</Form.Label>
              <Form.Control 
                type="text" 
                id="name" 
                placeholder="Digite seu nome completo"
                disabled={disableFields}
                {...register("name", { required: true })}/>
                {errors.name ? (<div className="w-100"> <Error>{errors.name?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col xs={12} className="mb-2">
            <Controller
              name="cpf"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <ReactInputMask mask="999.999.999-99" 
                  value={field.value}
                  onChange={field.onChange}
                >
                {(inputProps: any) => (
                  <Form.Group>
                    <Form.Label>CPF:</Form.Label>
                    <Form.Control 
                      {...inputProps}
                      disabled={disableFields}
                      placeholder="Digite seu CPF"/>
                  </Form.Group>
                )}
              </ReactInputMask>
              )}
            />
            {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
          </Col>

          <Col xs={12} className="mb-2">
            <Form.Group>
              <Form.Label>Seu melhor e-mail:</Form.Label>
              <Form.Control 
                type="text" 
                id="email" 
                placeholder="Digite seu melhor e-mail"
                {...register("email", { required: true })}/>
                {errors.email ? (<div className="w-100"> <Error>{errors.email?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col xs={12} className="mb-2">
            <Controller
              name="celular"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <ReactInputMask mask="(99) 99999-9999" 
                  value={field.value}
                  onChange={field.onChange}
                >
                {(inputProps: any) => (
                  <Form.Group>
                    <Form.Label>Celular com Whatsapp:</Form.Label>
                    <Form.Control 
                      {...inputProps}
                      placeholder="Digite seu celular"/>
                  </Form.Group>
                )}
              </ReactInputMask>
              )}
            />
            {errors.celular ? (<div className="w-100"> <Error>{errors.celular?.message}</Error> </div>) : ""}
          </Col>

          <Col xs={12} className="mb-2">
            <Controller
              name="cep"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <ReactInputMask mask="99999-999"
                  value={field.value}
                  onChange={field.onChange}
                >
                {(inputProps: any) => (
                  <Form.Group>
                    <Form.Label>CEP (Da cidade que o carro será utilizado):</Form.Label>
                    <Form.Control 
                      {...inputProps}
                      placeholder="Digite o CEP"/>
                  </Form.Group>
                )}
              </ReactInputMask>
              )}
            />
            {errors.cep ? (<div className="w-100"> <Error>{errors.cep?.message}</Error> </div>) : ""}
          </Col>

          <Col xs={12} className="mb-2">
            <Controller
              name="utilCar"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>O carro será utilizado para:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    options={optionsUtilCar}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.utilCar ? (<div className="w-100"> <Error>{errors.utilCar?.message}</Error> </div>) : ""}
          </Col>

          <Col xs={12}>
            <small style={{fontSize: '0.75rem', lineHeight: 'normal'}}>
              Ao avançar você concorda com a <a 
                className="btn-link" href={getPoliticaPrivacidade()} 
                style={{color: colors.mainColors.primary}}
                target="_blank" 
                rel="noreferrer">Política de Privacidade</a> e <a style={{color: colors.mainColors.primary}} className="btn-link" href={getTermosUso()} target="_blank" rel="noreferrer">Termos de Uso</a> da BYeCAR
            </small>
          </Col>
          <Col className="d-grid gap-2 col-6 mx-auto">
            <Button variant="primary" type="submit" className="mt-5 mb-5">Iniciar</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
import api from './api';

export const savePedido = async (objSubmit: any) => {
  var formData = getFormData(objSubmit);

  if(objSubmit.url_cnh){
    [...objSubmit.url_cnh].forEach((url_cnh) => {
      formData.append('url_cnh[]', url_cnh);
    });
  }
  if(objSubmit.url_comprovante_endereco){
    [...objSubmit.url_comprovante_endereco].forEach((url_comprovante_endereco: any) => {
      formData.append('url_comprovante_endereco[]', url_comprovante_endereco);
    });
  }

  if(objSubmit.url_fatura_cartao_credito){
    [...objSubmit.url_fatura_cartao_credito].forEach((url_fatura_cartao_credito: any) => {
      formData.append('url_fatura_cartao_credito[]', url_fatura_cartao_credito);
    });
  }

  if(objSubmit.url_comprovante_renda){
    [...objSubmit.url_comprovante_renda].forEach((url_comprovante_renda: any) => {
      formData.append('url_comprovante_renda[]', url_comprovante_renda);
    });
  }

  if(objSubmit.url_cnh_responsavel_financeiro){
    [...objSubmit.url_cnh_responsavel_financeiro].forEach((responsavel_financeiro_url_cnh: any) => {
      formData.append('url_cnh_responsavel_financeiro[]', responsavel_financeiro_url_cnh);
    });
  }

  if(objSubmit.url_contrato_social){
    [...objSubmit.url_contrato_social].forEach((url_contrato_social: any) => {
      formData.append('url_contrato_social[]', url_contrato_social);
    });
  }

  if(objSubmit.url_cartao_cnpj){
    [...objSubmit.url_cartao_cnpj].forEach((url_cartao_cnpj: any) => {
      formData.append('url_cartao_cnpj[]', url_cartao_cnpj);
    });
  }

  const { data } = await api.post(`out/pedidos`, formData, { headers: { 'Content-Type': `multipart/form-data;`} });
  return data;
}

function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    if(key !== "url_cnh" && 
        key !== "url_comprovante_endereco" && 
        key !== "url_comprovante_renda" && 
        key !== "url_contrato_social" && 
        key !== "url_fatura_cartao_credito" && 
        key !== "url_cartao_cnpj") {
      if (typeof object[key] !== 'object') {
        formData.append(key, object[key])
      } else {
        formData.append(key, JSON.stringify(object[key]))
      }
    }
  })
  return formData;
}

export const updateTagSemParar = async (idPedido: any, tagType: string) => {
  const { data } = await api.patch(`out/pedidos/${idPedido}?tag=${tagType}`);
  return data;
}
import { createGlobalStyle, css } from "styled-components";

import banner from "../assets/image/banner.png";

import {getColorsCurrentTheme} from "../services/theme.service";
const colors = getColorsCurrentTheme();

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: #b5b8bb;
    opacity: 1;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .btn-primary {
    background: ${colors.mainColors.primary} !important;
    border-color: ${colors.mainColors.primary} !important;
  }

  .btn-primary:hover {
    background-color: ${colors.mainColors.secondary} !important;
    border-color: ${colors.mainColors.secondary} !important;
  }
  
  .btn-primary:focus, .btn-primary:active {
    background-color: ${colors.mainColors.primary} !important;
    border-color: ${colors.mainColors.primary} !important;
    box-shadow: 0 0 0 .25rem ${colors.lightColors.primary} !important;
  }

  .btn-secondary {
    background: ${colors.mainColors.secondary} !important;
    border-color: ${colors.mainColors.secondary} !important;
  }

  .btn-secondary:hover, .btn-secondary:active {
    background-color: ${colors.mainColors.tertiary} !important;
    border-color: ${colors.mainColors.tertiary} !important;
  }


  .btn-grey {
    background: ${colors.greyscale.dark_grey} !important;
    border-color: ${colors.greyscale.dark_grey} !important;
  }

  .btn-grey:hover {
    background-color: ${colors.greyscale.darker_grey} !important;
    border-color: ${colors.greyscale.darker_grey} !important;
  }

  .btn-filter {
    height: 35px !important;
    border-radius: 25px;
    width: 45px;
    margin: 4px;
  }

  .mr-3 {
    margin-right: 15px;
  }
  
  .mr-2 {
    margin-right: 10px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 99%;
  }

  .spinner-border {
    width: 4.5rem;
    height: 4.5rem;
    border: 0.5em solid currentColor;
    border-right-color: transparent;
  }

  .spinner-button {
    width: 2rem;
    height: 2rem;
    border: 0.3em solid currentColor;
    border-right-color: transparent;
  }

  .row-home {

    @media (min-width: 768px) {
      h5 {
        font-size: 32px;
      }
    }
  }

  .bg-form {
    background: ${colors.bgForm};
    height: 100%;
    min-height: 100vh;

    h4, h5, h6, p, small, em, .form-check-label {
      color: ${colors.formCheckLabel} !important;
    }

    label {
      color: ${colors.mainColors.primary} !important;
    }

    .text-white {
      h4, h5, h6, p, small, .form-check-label {
        color: white !important;
      }
    }
  }

  .bg-feedback-success {
    background: ${colors.alertColors.approved};
  }

  .bg-feedback-error {
    background: ${colors.alertColors.denied};
  }

  .bg-finished-sign {
    background: ${colors.bgfinishedSign};
  }

  .logo-form {
    margin-top: 30px;
  }

  .wrapper-form {
    position: relative;
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;

    @media (min-width: 768px) {
      width: 660px;
    }
  }

  .card-model-selection {
    display: inline-block;
    height: 90px;
    width: 100%;
    padding: 5px;
    padding-top: 20px;
    font-size: 14px;
    background: ${colors.cardModelSelection.background};
    color: ${colors.cardModelSelection.color};

    .form-check {
      position: absolute;
      top: 40%;
      left: 5%;
    }
  }

  .model-selected {
    background: ${colors.mainColors.primary};
    color: ${colors.greyscale.white};
  }

  .badge {
    font-size: .85em;
  }

  .errorFeedback {
    font-size: 14px;
    color: ${colors.alertColors.danger};
    font-weight: 600;
    margin-top: 4px;
  }

  .accordion-modelquote {
    .accordion-item {
      background-color: ${colors.cardModelPlanSelection.background} !important;
    }
  
    .accordion-collapse {
      background-color: ${colors.cardModelPlanSelection.background};
    }

    .accordion-body{
      p{
        color: ${colors.cardModelPlanSelection.color}
      }
    }
  
    .accordion-button {
      background-color: ${colors.mainColors.secondary};
      color: ${colors.greyscale.white};
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &::after {
        filter: brightness(0) invert(1);
      }
  
      @media (min-width: 768px) {
        &::after {
          display: none;
        }
      }
    }

    .accordion-button:not(.collapsed) {
      background-color: ${colors.mainColors.primary};
      color: ${colors.greyscale.white};
  
      @media (min-width: 768px) {
        &::after {
          display: none;
        }
      }
    }
  }

  .banner-background {
    background: url(${banner});
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    
    @media (min-width: 1200px) and (max-width: 1399px){
      background-position: right;
      background-size: contain;
      height: 43vh;
    }

    @media (min-width: 1400px) {
      background-position: right;
      background-size: contain;
      height: 35vh;
    }
  }

  .btn-banner {
    @media (min-width: 1100px) {
      width: 40%;
    }
  }

  .img-category {
    -webkit-perspective: 0px !important;
    -webkit-backface-visibility: visible !important;
  }

  .form-background {
    height: 190px;
    background: ${colors.formBackgroundSteps !== null ? `url(${colors.formBackgroundSteps})` : `transparent`};
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    justify-content: end;
    display: flex;
    align-items: end;

    @media (min-width: 800px) {
      background-position: right;
      background-size: contain;
    }
  }

  .model-selection-text {
    margin-left: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .font-25 {
    font-size: 25px;
  }

  .collapse-home {
    .accordion-item {
      margin-bottom: 10px;
      border: 0px;

      .accordion-header {
        .accordion-button {
          background-color: ${colors.greyscale.light_grey};

          &:not(.collapsed) {
            color: ${colors.greyscale.black};
          }
          
          &:focus {
            z-index: 3;
            border: 0px;
            outline: 0;
            box-shadow: none;
            color: ${colors.greyscale.black};
          }
        }
      }
    }
  }

  ${({ theme }) => css`
    #root {
      min-height: 100vh;
    }

    body{
      font-family: 'Inter', 'Roboto', sans-serif !important;
    }

    h1, h2, h3, h4, h5, h6, p {
      color: ${colors.mainColors.secondary} !important;
      font-weight: 400 !important;
    }

    .btn-primary {
      color: ${colors.greyscale.white};
      background-color: ${colors.mainColors.primary};
      border-color: ${colors.mainColors.primary};
    }

    .btn-primary:hover {
      background-color: ${colors.mainColors.secondary};
      border-color: ${colors.mainColors.secondary};
    }

    .btn-outline-primary {
      color: ${colors.mainColors.primary};
      border-color: ${colors.mainColors.primary};
    }

    .btn-outline-primary:hover {
      color: ${colors.greyscale.white};
      background-color: ${colors.mainColors.primary};
      border-color: ${colors.mainColors.primary};
    }

    .banner {
      position: absolute;
      top: 5px;
      right: 0px;
      width: 262px;
      height: 239px;

      @media(max-width: 992px) {
        width: 262px;
        height: 239px;
      }
    }

    .text-banner {
      display: block;
      color: ${colors.mainColors.secondary} !important;
      font-weight: 400 !important;
      margin-top: 0;
      margin-bottom: 0.5rem;
      line-height: 1.2;
      font-size: 30px;

      @media(max-width: 550px) {
        font-size: 1rem;
      }
    }

    .video-player {
      iframe {  
        @media(max-width: 650px) {
          width: 100%;
          height: 100%;
        }

        @media(min-width: 651px) and (max-width: 992px) {
          height: 500px;
        }
      }
    }

    .text-primary {
      color: ${colors.mainColors.primary} !important;
    }

    .double-check {
      padding-right: 0px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .react-multi-carousel-list {
      height: 100% !important;
      padding-bottom: 15px;
    }

    .react-multi-carousel-track {
      @media(min-width: 1200px) {
        font-size: 14px;
      }
    }

    .carousel-promotion-list {
      .react-multi-carousel-item {
        display: flex;
        justify-content: center;
        
        @media(min-width: 701px) { 
          .card-promotion {
            opacity: 0.8;
          }
        }
      }
    }    

    .react-multi-carousel-item--active > .card-promotion{      
      @media(min-width: 701px) {
        opacity: 1;
        background: ${colors.mainColors.primary};
        transform: scale(1.1);
        
        h4, h6, p, small {
          color: ${colors.greyscale.white} !important;
        }
  
        .badge, button {
          background: ${colors.greyscale.white} !important;
          color: ${colors.mainColors.secondary} !important;
        }
      }
    }

    .card-promotion {
      width: 80%;
      height: 400px;
      border-radius: 15px;
      padding: 15px;
      background: ${colors.greyscale.light_grey};
      border: none;

      img {
        z-index: 99;
      }

      &:hover {
        background: ${colors.mainColors.primary};
        color: ${colors.greyscale.white} !important;

        p, h4, h6, small {
          color: ${colors.greyscale.white} !important;
        }
      }

      @media(max-width: 700px) {
        width: 95%;
        height: 500px;
      }

      @media(min-width: 701px) and (max-width: 1199px) {
        height: 100%;
      }

      @media(min-width: 1200px) {
        font-size: 14px;
        width: 75%;
        margin-left: 35px;
      }
    }

    .background-car-circle {
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      top: 22%;
      right: 25%;
      background: ${colors.mainColors.secondary};
    }

    .card-testimonial {
      width: 98%;
      height: 100%; 
      border-radius: 15px;
      background: ${colors.mainColors.secondary};
      padding: 15px;
      font-size: 12px;

      @media(min-width: 1200px) {
        font-size: 14px;
        width: 83%;
        margin-left: 35px;
      }
    }

    .row-testimonial::-webkit-scrollbar {
      width: 7px;
    }
    
    .row-testimonial::-webkit-scrollbar-thumb {
      background-color: ${colors.greyscale.dark_grey};
      border-radius: 100px;
    }

    .rating {
      color: ${colors.mainColors.orange};
      font-size: '14px';
    }

    .card-categories-active {
      background: ${colors.mainColors.primary} !important;

      p {
        color: ${colors.greyscale.white} !important;
      }
    }

    .card-categories {
      width: 92%;
      height: 100%; 
      border-radius: 15px;
      padding: 15px;
      font-size: 12px;
      background: ${colors.greyscale.light_grey};
      border: none;      

      &:hover {
        background: ${colors.mainColors.primary};

        p {
          color: ${colors.greyscale.white} !important;
        }
      }

      @media(min-width: 1200px) {
        font-size: 14px;
        width: 75%;
        margin-left: 35px;
      }
    }

    .title-card-car-details {
      padding: 1rem; 
      color: white; 
      background: ${colors.mainColors.primary}; 
      border-radius: 10px 5px 0px 0px;
    }

    .card-car-details {
      border-radius: 0px 0px 0.25rem 0.25rem;
      color: ${colors.cardCarPlanSelected.color};
      background: ${colors.cardCarPlanSelected.background};
    }

    .loading-semparar {
      color: ${colors.greyscale.white} !important;
      width: 3rem;
      height: 3rem;
      border: 0.4em solid currentColor;
      border-right-color: transparent;
    }

    .litle-spinner {
      width: 2rem;
      height: 2rem;
      border: 0.35em solid currentColor;
      border-right-color: transparent;
    }

  `}
`;

export default GlobalStyle
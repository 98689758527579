import { useContext, useEffect } from 'react';
import { Spinner } from "react-bootstrap"

//services
import { criarConsultaSerasa } from "../../../services/ConsultaSerasa";
import {createLead} from "../../../services/lead.service";

//utils
import { FEEDBACK_CODE, SIGN_CAR_STEPS } from "../../../@enums/index";

//components
import UsuarioContext from "../../../context/userContext/context";

export const VerifyRegister = (props: any): JSX.Element => {
  const { user, setLocadorasParams, setFeedbackCode, 
    setFeedbackMessage, nextStep, setSignData, signData } = props;

  const { user: userContext } = useContext(UsuarioContext);

  useEffect(() => {
    user.utmParams = userContext.utmParams;

    const consultarSerasa: any = process.env.REACT_APP_CONSULTAR_SERASA!;
    if(parseInt(consultarSerasa) === 1){
        criarConsultaSerasa(user).then((response) => {
          if(response.status === 200) {
            const data: any = response.data;
            
            if(data.resultado_analise !== null && data.resultado_analise.validado === true){
              setFeedbackCode(FEEDBACK_CODE.success);
              setLocadorasParams(data.resultado_analise);
              setSignData({...signData, lead_id: data.lead_id});
              nextStep(SIGN_CAR_STEPS.analysisFeedback);
            }else{
              setFeedbackCode(FEEDBACK_CODE.error);
              nextStep(SIGN_CAR_STEPS.analysisFeedback);
            }
          }else{
            setFeedbackMessage(response.data.error);
            setFeedbackCode(FEEDBACK_CODE.error);
            nextStep(SIGN_CAR_STEPS.analysisFeedback);
          }    
        }).catch((error) => {
          // Error
          if (error.response) {
            setFeedbackMessage(error.response.data.error);
          } else{
            setFeedbackMessage("Ocorreu um erro no prenchimento do formulário. Tente novamente em alguns minutos.");
          }
          setFeedbackCode(FEEDBACK_CODE.error);
          nextStep(SIGN_CAR_STEPS.analysisFeedback);
        })
    }else{
      createLead(user).then((response) => {
        if(response.status === 200) {
          const data: any = response.data;
          
          if(data.resultado_analise !== null && data.resultado_analise.validado === true){
            setFeedbackCode(FEEDBACK_CODE.success);
            setLocadorasParams(data.resultado_analise);
            setSignData({...signData, lead_id: data.lead_id});
            nextStep(SIGN_CAR_STEPS.analysisFeedback);
          }else{
            setFeedbackCode(FEEDBACK_CODE.error);
            nextStep(SIGN_CAR_STEPS.analysisFeedback);
          }
        }else{
          setFeedbackMessage(response.data.errors);
          setFeedbackCode(FEEDBACK_CODE.error);
          nextStep(SIGN_CAR_STEPS.analysisFeedback);
        }    
      }).catch((error) => {
        // Error
        setFeedbackMessage("Ocorreu um erro no prenchimento do formulário. Tente novamente em alguns minutos.");
        setFeedbackCode(FEEDBACK_CODE.error);
        nextStep(SIGN_CAR_STEPS.analysisFeedback);
      })
    }
  },[]);

  return (
    <div className="text-center mt-5">
      <Spinner animation="border" variant="primary" style={{ margin: '15px' }} />
      <h5 className="mt-4"><b>Estamos analisando seus dados!</b></h5>
      <p>Não se preocupe, leva menos de 1 minuto ...</p>
    </div>
  )
}
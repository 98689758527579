import React from 'react';

import { UsuarioContextProvider } from './userContext/context';

const GlobalContext: React.FC = ({ children }) => {
  return (
    <>
      <UsuarioContextProvider>
        {children}
      </UsuarioContextProvider>
    </>
  );
};

export default GlobalContext;
export function formatDate(date: string | Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
}

export function formatReal(value: string | number){
  value = value+'';
  const int = parseInt( value.replace(/[\D]+/g,'') );
  var tmp = int+'';
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

  if( tmp.length > 6 ) {
    tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  return tmp;
}

export function formatCelularNumber(value: string){
  value = value.replace( /\D/g , "");
  value = value.replace(/^(\d{2})(\d)/g,"($1) $2"); 
  return value.replace(/(\d)(\d{4})$/,"$1-$2");
}

export function formatCPF(value: string){
  value = value.replace( /(\d{3})(\d)/ , "$1.$2");
  value = value.replace( /(\d{3})(\d)/ , "$1.$2");
  return value.replace(/(\d{3})(\d{1,2})$/ , "$1-$2");
}

export function cpfValido(cpf: string) {
  let numeros: string, digitos, soma, i, resultado;
  let digitos_iguais = 1;
  if (cpf.length < 11) {
      return false;
  }
  for (i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
      }
  }
  if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) {
          soma += parseInt(numeros.charAt(10 - i)) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(0))) {
          return false;
      }
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) {
          soma += parseInt(numeros.charAt(11 - i)) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(1))) {
          return false;
      }
      return true;
  } else {
      return false;
  }
}
import { Col, Card, Row } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import { TestimonialCarousel } from "../../../models/Carousel";

export const TestimonialType = (props: TestimonialCarousel): JSX.Element => {
  return (
    <Card className="d-flex justify-content-between card-testimonial">
      <div>
        <p className="text-white">
          “{props.testimonial}”
        </p>
      </div>
      <div>
        <span className="rating">
          <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
        </span>
        <p className="text-white mb-0">
          <b>{props.clientName}</b> - {props.clientCity} <br />
          <small>Assinou um {props.carModel}</small>
        </p>
      </div>
    </Card>
  );
};
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { updateLead } from '../../services/lead.service';

//steps
import { ClientInfos } from './components/ClientInfos';
import { VerifyRegister } from './components/VerifyRegister';
import { AnalysisFeedback } from './components/AnalysisFeedback';
import { RentalFilter } from './components/RentalFilter';
import { ModelSelection } from './components/ModelSelection';
import { ModelQuote } from './components/ModelQuote';
import { ComplementaryInfos } from './components/ComplementaryInfos';
import { TagSemParar } from './components/TagSemParar';
import { SuccessSign } from './components/SuccessSign';

//components
import { Col, Row } from "react-bootstrap"

//utils
import { FEEDBACK_CODE, SIGN_CAR_STEPS } from "../../@enums/index";
import { SignDataProps } from '../../models/signCar';
import { getLogoAccordingToStep } from "../../services/theme.service";

export const SignCar = (): JSX.Element => {
  const [user, setUser] = useState();
  const [contactRequestedShowMessage, setContactRequestedShowMessage] = useState<boolean>(true);
  const [signData, setSignData] = useState<SignDataProps>();
  const [indexStep, setIndexStep] = useState<number>(SIGN_CAR_STEPS.clientInfo);
  const [stage, setStage] = useState<number>(1);
  const [feedbackCode, setFeedbackCode] = useState<number>();
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const [locadorasParams, setLocadorasParams] = useState<any>();
  const [requestId, setRequestId] = useState<number>();
  const [filtersCategories, setFiltersCategories] = useState<any>({
    tipoContratacao: null, formaPagamento: null,
    franquiaKm: null, categoria: null, consegueComprovarRenda: "sim"
  });
  const currentParams: any = useParams();

  const [rentalFilters, setRentalFilters] = useState<any>({
    tipoContratacao: null, formaPagamento: null,
    franquiaKm: null, categoria: null, consegueComprovarRenda: ''
  });

  const [franchiseDefaultValue, setFranchiseDefaultValue] = useState()
  const [categoryDefaultValue, setCategoryDefaultValue] = useState()

  const handleSwitch = (event: any) => {
    setSignData({ ...signData, contactRequested: event.target.checked });
    updateLead(signData.lead_id, event.target.checked);
  }

  //controle visual dos campos
  const [tiposContratacaoDisabled, setTiposContratacaoDisabled] = useState<boolean>(true);
  const [formasPagamentoDisabled, setFormasPagamentoDisabled] = useState<boolean>(true);
  const [franquiasKmDisabled, setFranquiasKmDisabled] = useState<boolean>(true);
  const [categoriasDisabled, setCategoriasDisabled] = useState<boolean>(true);
  
  /*
  useEffect(() => {
    console.log(rentalFilters);
  }, [rentalFilters]);
  */


  const classes =
    (indexStep === SIGN_CAR_STEPS.analysisFeedback ?
      feedbackCode === FEEDBACK_CODE.error ? "bg-feedback-error" : "bg-feedback-success"
      : indexStep === SIGN_CAR_STEPS.successSign || indexStep === SIGN_CAR_STEPS.tagSemParar ? "bg-finished-sign" : "") + " d-flex justify-content-center bg-form"

  useEffect(() => {
    switch (indexStep) {
      case 1 || 2 || 3:
        setStage(1);
        break
      case 4:
        setStage(2);
        break
      case 5:
        setStage(3);
        break
      case 6:
        setStage(4);
        break
      case 7 || 8:
        setStage(5);
        break
    }

  }, [indexStep]);

  const steps = [
    <ClientInfos nextStep={setIndexStep} indexStep={indexStep} setUser={setUser}/>,

    <VerifyRegister user={user} setUser={setUser}
      setFeedbackCode={setFeedbackCode} nextStep={setIndexStep}
      setLocadorasParams={setLocadorasParams} signData={signData}
      setSignData={setSignData} indexStep={indexStep}
      setFeedbackMessage={setFeedbackMessage} />,

    <AnalysisFeedback
      variant={feedbackCode === FEEDBACK_CODE.error ? "error" : "success"}
      feedbackMessage={feedbackMessage}
      locadorasParams={locadorasParams} indexStep={indexStep}
      user={user} nextStep={setIndexStep} />,

    <RentalFilter signData={signData}
      contactRequestedShowMessage={contactRequestedShowMessage}
      setContactRequestedShowMessage={setContactRequestedShowMessage}
      handleSwitch={handleSwitch}
      setSignData={setSignData} indexStep={indexStep}
      locadorasParams={locadorasParams} setFiltersCategories={setFiltersCategories}
      nextStep={setIndexStep} 
      rentalFilters={rentalFilters} setRentalFilters={setRentalFilters} 
      tiposContratacaoDisabled={tiposContratacaoDisabled} setTiposContratacaoDisabled={setTiposContratacaoDisabled}
      formasPagamentoDisabled={formasPagamentoDisabled} setFormasPagamentoDisabled={setFormasPagamentoDisabled}
      franquiasKmDisabled={franquiasKmDisabled} setFranquiasKmDisabled={setFranquiasKmDisabled}
      categoriasDisabled={categoriasDisabled} setCategoriasDisabled={setCategoriasDisabled}
      franchiseDefaultValue={franchiseDefaultValue} setFranchiseDefaultValue={setFranchiseDefaultValue}
       />,

    <ModelSelection nextStep={setIndexStep}
      signData={signData} indexStep={indexStep}
      rentalFilters={rentalFilters} setRentalFilters={setRentalFilters} 
      setSignData={setSignData} filtersCategories={filtersCategories} setFiltersCategories={setFiltersCategories}
      locadorasParams={locadorasParams}
      user={user}
      categoryDefaultValue={categoryDefaultValue} setCategoryDefaultValue={setCategoryDefaultValue} />,

    <ModelQuote
      signData={signData}
      contactRequestedShowMessage={contactRequestedShowMessage}
      setContactRequestedShowMessage={setContactRequestedShowMessage}
      handleSwitch={handleSwitch}
      setSignData={setSignData}
      nextStep={setIndexStep} indexStep={indexStep}
      locadorasParams={locadorasParams}
      setLocadorasParams={setLocadorasParams}
      user={user}
    />,

    <ComplementaryInfos
      setSignData={setSignData} signData={signData}
      contactRequestedShowMessage={contactRequestedShowMessage}
      setContactRequestedShowMessage={setContactRequestedShowMessage}
      handleSwitch={handleSwitch}
      nextStep={setIndexStep} indexStep={indexStep}
      locadorasParams={locadorasParams}
      user={user}
      setRequestId={setRequestId}
    />,

    <TagSemParar
      requestId={requestId}
      nextStep={setIndexStep}
    />,

    <SuccessSign />,
  ];

  return (
    <>
      <Col className={classes}>
        <Row className="wrapper-form" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col xs={8}>
            <img style={{ maxHeight: "70px" }} className="mt-5" src={getLogoAccordingToStep(indexStep)} alt="" />
          </Col>
          {
            indexStep !== SIGN_CAR_STEPS.verifyRegister && indexStep !== SIGN_CAR_STEPS.analysisFeedback && indexStep !== SIGN_CAR_STEPS.successSign && indexStep !== SIGN_CAR_STEPS.tagSemParar ? (
              <Col xs={4} className="form-background">
                <p className="text-center">Etapa {stage}/5</p>
              </Col>
             ) : (null)
          }
          <Col xs={12}>
            {steps[indexStep]}
          </Col>
        </Row>
      </Col>
    </>
  )
}

import * as themes from '../theme/schema.json';
type ObjectKey = keyof typeof themes.data;

const getCurrentTheme = (): ObjectKey => {
  return process.env.REACT_APP_NAME! as ObjectKey;
}

export const getEnderecoRodape = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].enderecoRodape;
}

export const getTermosUso = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].termosUso;
}

export const getPoliticaPrivacidade = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].politicaPrivacidade;
}

export const getColorsCurrentTheme = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].colors;
}

export const getUrlImgJourneyWeb = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].urlImgJourneyWeb;
}

export const getUrlImgJourneyMobile = () => {
  const currentTheme = getCurrentTheme();
  console.log(currentTheme);
  return themes.data[currentTheme].urlImgJourneyMobile;
}

export const getUrlImgLogoDefault = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].urlImgLogoDefault;
}

export const getUrlImgLogoWhite = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].urlImgLogoWhite;
}

export const getSocialMedias = () => {
  const currentTheme = getCurrentTheme();
  return themes.data[currentTheme].socialMedias;
}


export const getLogoAccordingToStep = (stepNumber: number) => {
  const currentTheme = getCurrentTheme();
  const logoWhite = getUrlImgLogoWhite();
  const logoDefault = getUrlImgLogoDefault();

  if(currentTheme === "SemParar" && stepNumber === 2){
    return logoWhite;
  }else if(currentTheme === "SemParar"){
    return logoDefault;
  }

  if(currentTheme === "Byecar"){
    switch(stepNumber){
      case 2:
      case 7:
      case 8:
        return logoWhite;
      default:
        return logoDefault;
    }
  }

  // if(currentTheme === "SantaEmilia"){
    return logoDefault;
  // }
}


import * as themes from '../../../theme/schema.json';
type ObjectKey = keyof typeof themes.data;
const currentTheme = process.env.REACT_APP_NAME! as ObjectKey;
const colors = themes.data[currentTheme].colors;

export const CustomDot = ({ active }: any): JSX.Element => {
  return (
    <div style={{ backgroundColor: active ? colors.mainColors.primary : "#CECECE", 
      borderRadius:'5px', height: '10px', width: '15px', marginRight:'10px', 
      transition: "width .5s ease-in-out, background-color .5s ease-in-out" }}>
    </div>
  );
};
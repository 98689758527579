import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../../@enums/index";
import { optionsCivilState, optionsSexUser, optionsYesOrNo } from "../../../../@options";
import 'dotenv/config';
import { useHistory } from "react-router-dom";

//components
import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import Select from 'react-select';
import { Error } from "../../../../components/Error";

//services
import { getStatesList } from "../../../../services/signCar.service";
import { savePedido } from "../../../../services/submitForms.service";
import { listarCores, listarOpcionais } from "../../../../services/locadorasParams.service";
import { cpfValido } from "../../../../helpers";

export const FormMovida = (props: any): JSX.Element => {
  const { nextStep, locadorasParams, signData, 
    loading, setLoading, user, setRequestId, handleClickBack } = props;
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [showFormResponsavelFinanceiro, setShowFormResponsavelFinanceiro] = useState<boolean>(false);
  const [listOptionals, setListOptionals] = useState([]);
  const [optionalsSelected, setOptionalsSelected] = useState([]);
  const [colors, setColors] = useState([]);

  const validationSchema = Yup.object({
    possuiResponsavelFinanceiro: Yup.object().required('Este campo é obrigatório'),
    dataNascimento: Yup.string().required('A data de nascimento é obrigatória'),
    logradouro: Yup.string().required('O logradouro é obrigatório'),
    numero: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string().required('O bairro é obrigatório'),
    cidade: Yup.string().required('A cidade é obrigatória'),
    estado: Yup.object().required('O estado é obrigatório'),
    nomeResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    cpfResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    emailResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().email('E-mail inválido').required('Este campo é obrigatório') : Yup.string(),
    celularResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    cepResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    logradouroResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    numeroResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string() : Yup.string(),
    complementoResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string() : Yup.string(),
    bairroResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    cidadeResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    estadoResponsavelFinanceiro: showFormResponsavelFinanceiro ? Yup.object().required('Este campo é obrigatório') : Yup.string(),
  }).required();
  
  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
  const history = useHistory()

  const [optionStates, setOptionStates] = useState([]);

  const onSubmit = (formData: any) => {
    setLoadingSubmit(true);
    let objSubmit = {
      pedido: {
        locadora_id: signData.locadoraId,
        veiculo_id: signData.selectedCar.veiculo_id,
        tempo_contrato: signData.selectedCar.contractTime,
        franquia_km: signData.franchise,
        forma_pagamento: signData.payWay,
        tipo_contrato: signData.contractType,
        opcionais: optionalsSelected,
        cor_id: formData.corCarro.value ?? "",
      },
      cliente: {
        lead_id: signData.lead_id,
        nome_completo: user.name,
        num_cpf: user.cpf,
        email: user.email,
        num_celular: user.celular,
        data_nascimento: formData.dataNascimento,
        endereco: {
          num_cep: user.cep,
          logradouro: formData.logradouro,
          numero: formData.numero,
          complemento: formData.complemento,
          bairro: formData.bairro,
          cidade: formData.cidade,
          sigla_estado: formData.estado.value
        },
        possui_responsavel_financeiro: formData.possuiResponsavelFinanceiro.value,
        responsavel_financeiro: {
          nome_completo: showFormResponsavelFinanceiro ? formData.nomeResponsavelFinanceiro : "",
          num_cpf: showFormResponsavelFinanceiro ? formData.cpfResponsavelFinanceiro : "",
          email: showFormResponsavelFinanceiro ? formData.emailResponsavelFinanceiro : "",
          num_celular: showFormResponsavelFinanceiro ? formData.celularResponsavelFinanceiro : "",
          endereco: {
            num_cep: showFormResponsavelFinanceiro ? formData.cepResponsavelFinanceiro : "",
            logradouro: showFormResponsavelFinanceiro ? formData.logradouroResponsavelFinanceiro : "",
            numero: showFormResponsavelFinanceiro ? formData.numeroResponsavelFinanceiro : "",
            complemento: showFormResponsavelFinanceiro ? formData.complementoResponsavelFinanceiro : "",
            bairro: showFormResponsavelFinanceiro ? formData.bairroResponsavelFinanceiro : "",
            cidade: showFormResponsavelFinanceiro ? formData.cidadeResponsavelFinanceiro : "",
            sigla_estado: showFormResponsavelFinanceiro ? formData.estadoResponsavelFinanceiro.value : ""
          }
        }
      },
      url_cnh_responsavel_financeiro: ""
    }

    savePedido(objSubmit).then((response) => {
      setRequestId(response.order)
      const tagSemPararStep = process.env.REACT_APP_SHOW_TAGSEMPARAR_STEP;
      nextStep((parseInt(tagSemPararStep) === 1 ? 
        SIGN_CAR_STEPS.tagSemParar : 
        SIGN_CAR_STEPS.successSign));
      setLoadingSubmit(false);
      history.push(`/sign-car/sem-parar`)
    }).catch((err) => {
      setLoadingSubmit(false);
      toast.error(err);
    })
  }

  useEffect(() => {
    setListOptionals(listarOpcionais(locadorasParams, signData.locadoraId));
    setColors(listarCores(JSON.parse(signData.selectedCar.json_cores)));
    getStatesList().then((response) => {
      let userStateSelect = ""
      setOptionStates(response.map((estado: any) => {
        if (estado.sigla === user.address.estado) {
          userStateSelect = estado.nome + ' - ' + estado.sigla;
        }
        return {
          label: estado.nome + ' - ' + estado.sigla,
          value: estado.sigla,
        }
      }));
      setValue('estado', { value: user.address.estado, label: userStateSelect })
      setLoading(false);
    });
  }, []);
  
  return (
    <div className={loading ? 'd-none' : 'mt-5'}>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        {
          (colors.length > 0 || listOptionals.length > 0) &&
          <h5>Personalize seu contrato</h5>
        }
        <Row>
          {
            colors.length > 0 &&
            <Col md={12} className="mb-2">
              <Controller
                name="corCarro"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Cores:</Form.Label>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={colors}
                      placeholder="Selecione..."
                      defaultValue=""
                      />
                  </Form.Group>
                )}
              />
            </Col>
          }

          {
            listOptionals.length > 0 &&
            <Col xs={12} className="mb-2">
              <Form.Label>Opcionais:</Form.Label><br/>
              {
                listOptionals.map((optional, index) => (
                  <Form.Check
                    key={index}
                    inline
                    label={optional.label}
                    type="checkbox"
                    id={optional.value}
                    onChange={() => {
                      if(!optionalsSelected.includes(optional.value)) {
                        setOptionalsSelected([...optionalsSelected, optional.value])
                      } else {
                        let arr = optionalsSelected;
                        setOptionalsSelected(arr.filter(x => x !== optional.value))
                      }
                    }}
                  />
                ))
              }
            </Col>
          }
          
        </Row>

        <h5 className="mt-4">Agora precisamos dos últimos detalhes da sua assinatura</h5>
        <Row>
        <Col md={6} className="mb-2">
            <Controller
              name="cpf"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <ReactInputMask mask="999.999.999-99"
                  value={user.cpf}
                  readOnly={true}
                  onChange={field.onChange}
                >
                  {(inputProps: any) => (
                    <Form.Group>
                      <Form.Label>CPF:</Form.Label>
                      <Form.Control
                        defaultValue=""
                        {...inputProps}
                        readOnly
                        placeholder="Digite seu CPF" />
                    </Form.Group>
                  )}
                </ReactInputMask>
              )}
            />
            {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Data de Nascimento:</Form.Label>
              <Form.Control 
                type="date"
                min="1900-01-01"
                max="3000-12-31"
                {...register("dataNascimento", { required: true })}
                placeholder="Digite a sua data de nascimento"/>
            </Form.Group>
            {errors.dataNascimento ? (<div className="w-100"> <Error>{errors.dataNascimento?.message}</Error> </div>) : ""}
          </Col>

          <Col md={8} className="mb-2">
            <Form.Group>
              <Form.Label>Logradouro:</Form.Label>
              <Form.Control 
                type="text" 
                defaultValue={user && user.address.logradouro ? user.address.logradouro : ""}
                placeholder="Logradouro"
                {...register("logradouro", { required: true })}/>
                {errors.logradouro ? (<div className="w-100"> <Error>{errors.logradouro?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>Número:</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Nº"
                {...register("numero")}/>
            </Form.Group>
          </Col>

          <Col md={8} className="mb-2">
            <Form.Group>
              <Form.Label>Complemento:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Complemento"
                {...register("complemento")}/>
            </Form.Group>
          </Col>

          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>Bairro:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite o bairro"
                defaultValue={user && user.address.bairro ? user.address.bairro : ""}
                {...register("bairro", { required: true })}/>
                {errors.bairro ? (<div className="w-100"> <Error>{errors.bairro?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label>Cidade:</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Digite a cidade"
                defaultValue={user && user.address.cidade ? user.address.cidade : ""}
                {...register("cidade", { required: true })}/>
                {errors.cidade ? (<div className="w-100"> <Error>{errors.cidade?.message}</Error> </div>) : ""}
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Controller
              name="estado"
              control={control}
              rules={{ required: true }}
              defaultValue="ES"
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Estado:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    options={optionStates}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.estado ? (<div className="w-100"> <Error>{errors.estado?.message}</Error> </div>) : ""}
          </Col>

          <Col md={12} className="mb-2">
            <Controller
              name="possuiResponsavelFinanceiro"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>Possui Responsável Financeiro:</Form.Label>
                  <Select
                    {...field}
                    isSearchable={false}
                    onChange={(e: any) => {setValue('possuiResponsavelFinanceiro', e); setShowFormResponsavelFinanceiro(e.value ==='sim' ? true : false)}}
                    options={optionsYesOrNo}
                    placeholder="Selecione..."
                    />
                </Form.Group>
              )}
            />
            {errors.possuiResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.possuiResponsavelFinanceiro?.message}</Error> </div>) : ""}
          </Col>

          {
            showFormResponsavelFinanceiro && (
              <>
                <h5 className="mt-5">Responsável Financeiro</h5>
                <hr style={{color: 'white'}}/>
                <Row>
                  <Col md={12} className="mb-2">
                    <Form.Group>
                      <Form.Label>Nome:</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Digite o nome"
                        {...register("nomeResponsavelFinanceiro", { required: true })}/>
                        {errors.nomeResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.nomeResponsavelFinanceiro?.message}</Error> </div>) : ""}
                    </Form.Group>
                  </Col>

                  <Col md={12} className="mb-2">
                    <Form.Group>
                      <Form.Label>E-mail:</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Digite o e-mail"
                        {...register("emailResponsavelFinanceiro", { required: true })}/>
                        {errors.emailResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.emailResponsavelFinanceiro?.message}</Error> </div>) : ""}
                    </Form.Group>
                  </Col>

                  <Col md={12} className="mb-2">
                    <Controller
                      name="cpfResponsavelFinanceiro"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <ReactInputMask mask="999.999.999-99"
                          value={field.value}
                          onChange={field.onChange}
                        >
                        {(inputProps: any) => (
                          <Form.Group>
                            <Form.Label>CPF:</Form.Label>
                            <Form.Control 
                              {...inputProps}
                              placeholder="Digite o CPF"/>
                          </Form.Group>
                        )}
                      </ReactInputMask>
                      )}
                    />
                    {errors.cpfResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.cpfResponsavelFinanceiro?.message}</Error> </div>) : ""}
                  </Col>

                  <Col md={12} className="mb-2">
                    <Controller
                      name="celularResponsavelFinanceiro"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <ReactInputMask mask="(99) 99999-9999" 
                          value={field.value}
                          onChange={field.onChange}
                        >
                        {(inputProps: any) => (
                          <Form.Group>
                            <Form.Label>Celular:</Form.Label>
                            <Form.Control 
                              {...inputProps}
                              placeholder="Digite o celular"/>
                          </Form.Group>
                        )}
                      </ReactInputMask>
                      )}
                    />
                    {errors.celularResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.celularResponsavelFinanceiro?.message}</Error> </div>) : ""}
                  </Col>

                  <Col md={12} className="mb-2">
                    <Controller
                      name="cepResponsavelFinanceiro"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <ReactInputMask mask="99999-999" 
                          value={field.value}
                          onChange={field.onChange}
                        >
                        {(inputProps: any) => (
                          <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control 
                              {...inputProps}
                              placeholder="Digite o CEP"/>
                          </Form.Group>
                        )}
                      </ReactInputMask>
                      )}
                    />
                    {errors.cepResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.cepResponsavelFinanceiro?.message}</Error> </div>) : ""}
                  </Col>

                  <Col md={9} className="mb-2">
                    <Form.Group>
                      <Form.Label>Logradouro:</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Logradouro"
                        {...register("logradouroResponsavelFinanceiro", { required: true })}/>
                        {errors.logradouroResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.logradouroResponsavelFinanceiro?.message}</Error> </div>) : ""}
                    </Form.Group>
                  </Col>

                  <Col md={3} className="mb-2">
                    <Form.Group>
                      <Form.Label>Número:</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Nº"
                        {...register("numeroResponsavelFinanceiro")}/>
                    </Form.Group>
                  </Col>

                  <Col xs={12} className="mb-2">
                    <Form.Group>
                      <Form.Label>Complemento:</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Complemento"
                        {...register("complementoResponsavelFinanceiro")}/>
                    </Form.Group>
                  </Col>

                  <Col md={4} className="mb-2">
                    <Form.Group>
                      <Form.Label>Bairro:</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Digite o bairro"
                        {...register("bairroResponsavelFinanceiro", { required: true })}/>
                        {errors.bairroResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.bairroResponsavelFinanceiro?.message}</Error> </div>) : ""}
                    </Form.Group>
                  </Col>

                  <Col md={4} className="mb-2">
                    <Form.Group>
                      <Form.Label>Cidade:</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Digite a cidade"
                        {...register("cidadeResponsavelFinanceiro", { required: true })}/>
                        {errors.cidadeResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.cidadeResponsavelFinanceiro?.message}</Error> </div>) : ""}
                    </Form.Group>
                  </Col>

                  <Col md={4} className="mb-2">
                    <Controller
                      name="estadoResponsavelFinanceiro"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Group>
                          <Form.Label>Estado:</Form.Label>
                          <Select
                            {...field}
                            isSearchable={false}
                            options={optionStates}
                            placeholder="Selecione..."
                            />
                        </Form.Group>
                      )}
                    />
                    {errors.estadoResponsavelFinanceiro ? (<div className="w-100"> <Error>{errors.estadoResponsavelFinanceiro?.message}</Error> </div>) : ""}
                  </Col>
                </Row>
              </>
            )
          }

          <Col md={6} className="mt-5 mb-5 d-grid gap-2 mx-auto">
            {
              loadingSubmit ? (
                <div className="d-flex justify-content-center mb-3">
                  <Spinner animation="border" variant="primary" className="spinner-button" />
                </div>
              ) : (
                <Button type="submit" className="w-100 mb-3">Concluir</Button>
              )
            }
            <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
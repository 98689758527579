import { useContext } from "react";
import {useHistory} from "react-router-dom";
import { Button } from 'react-bootstrap';
import UsuarioContext from "../../../context/userContext/context";

import carError from '../../../assets/image/car-error.svg'
import carSuccess from '../../../assets/image/car-success.svg'

import { SIGN_CAR_STEPS } from "../../../@enums/index";

export const AnalysisFeedback = (props: any): JSX.Element => {
  const { variant, user, nextStep, feedbackMessage, indexStep } = props;
  const { user: userContext } = useContext(UsuarioContext);
  const history = useHistory();

  const handleClickNext = () => {
    const next: number = SIGN_CAR_STEPS.rentalFilter;
    const params = new URLSearchParams(window.location.search);
    let target_url = params.get('vid') !== undefined && params.get('vid') !== null ? 
        `?vid=${params.get('vid')}&lid=${params.get('lid')}&cid=${params.get('cid')}&cname=${params.get('cname')}` : 
        '';
    history.push(`/sign-car/contrato${target_url}`);
    nextStep(next);
  }
  const handleClickBack = () => {
    const prev: number = SIGN_CAR_STEPS.clientInfo;
    nextStep(prev);
  }

  if (variant === 'error') {
    return (
      <div className="text-center mt-5 text-white">
        <img width="30%" src={carError} alt="" />
        <h5 className="mt-4 mb-4">
          <b>
            {user.name}, 
            {feedbackMessage !== "" ? 
              (<p>{feedbackMessage}</p>) : 
              (
                ` não encontramos nenhuma oferta de assinatura no seu perfil.`
              )
            }
          </b>
        </h5>
        {feedbackMessage === "" ? 
            (
              <>
                <p>Entendemos que pode ser frustrante, mas isso não significa 
                que você não possa assinar seu carro conosco em breve, estamos 
                sempre evoluindo nossas análises.</p>
                {userContext.token ? '' : <p>Clique em <b>Nova Análise</b> para fazer o cadastro em nome de outra pessoa.</p>}
              </>
            ) : ("")
        }
        <div className="row">
          <div className="d-grid gap-2 col-6 mx-auto mt-5">
            <Button className="btn-primary" onClick={() => handleClickBack()}>
              {feedbackMessage === "" ? "Nova análise" : "Voltar"}
            </Button>
            <Button className="btn-secondary" onClick={() => window.location.href = "/"}>Ir para o site</Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="text-center mt-5 text-white">
        <img width="25%" src={carSuccess} alt="" />
        <h5 className="mt-4 mb-4" style={{ color: "white" }}><b>Hey {user.name}, parabéns! Seu cadastro foi pré-aprovado.</b></h5>
        <p>Agora vamos encontrar a melhor opção pra você.</p>

        <div className="row">
          <div className="d-grid gap-2 col-6 mx-auto">
            <Button variant="secondary" className="mt-5 w-100" onClick={() => handleClickNext()}>Montar meu plano</Button>
          </div>
        </div>
      </div>
    )
  }
}
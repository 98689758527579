import React, { createContext, useCallback } from "react";
import { UserType, PropsUserType } from "./props";
import { useLocalStorage } from "react-use";

export const DEFAULT_VALUE = {
  user: {
    token: "",
    utmParams: {}
  },
  update: () => { },
};

const UsuarioContext = createContext<PropsUserType>(DEFAULT_VALUE);

const UsuarioContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useLocalStorage<UserType>("@semPararUser", DEFAULT_VALUE.user);

  const update = useCallback(
    (settings: UserType) => {
      setData(settings);
    },
    [setData]
  );

  return (
    //@ts-ignore
    <UsuarioContext.Provider value={{ user: data, update }}>{children}</UsuarioContext.Provider>
  );
};

export { UsuarioContextProvider };
export default UsuarioContext;

import { useContext, useState } from "react";
import { Button, Spinner } from "react-bootstrap"

import { SIGN_CAR_STEPS } from "../../../@enums";
import UsuarioContext from "../../../context/userContext/context";

import { updateTagSemParar } from "../../../services/submitForms.service";

import imgTagSemParar from "../../../assets/image/tag-semparar.png";

export const TagSemParar = (props: any): JSX.Element => {
  const { requestId, nextStep } = props;
  const { user } = useContext(UsuarioContext);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (tag: string) => {
    setLoading(true);
    updateTagSemParar(requestId, tag).then(() => {
      nextStep(SIGN_CAR_STEPS.successSign)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className="text-center">
      <img className="img-fluid" src={imgTagSemParar} alt="" />
      <h5 className="mt-4 mb-4">
      {
        (process.env.REACT_APP_NAME === "SemParar") ? (
          <>
            <b>Heey, boa notícia!</b> Temos uma condição especial para você ter ainda mais comodidade com seu carro novo,&nbsp;
            são <b>4 mensalidades</b> sem custos e um <b>voucher de R$ 150 reais</b> para abastecimento!
          </>
        ) : (
          <>
            <b>Heey, boa notícia!</b> Temos uma condição especial com a <b>Sem Parar</b> para você ter ainda mais comodidade com seu carro novo,&nbsp;
            são <b>6 mensalidades</b> sem custos!
          </>
        )
      }
      </h5>

      {
        loading ? (
          <Spinner className="loading-semparar" animation="border" variant="primary"/>
        ) : (
          <>
            <Button className="mt-5 w-100 btn-secondary" onClick={() => handleSubmit("new")}>Quero uma TAG adicional</Button>
            {
              (process.env.REACT_APP_NAME === "SemParar") ? (
                <Button className="mt-2 w-100 btn-grey" onClick={() => handleSubmit("change")}>Vou substituir a minha TAG (novo carro)</Button>
              ) : (
                <Button className="mt-2 w-100 btn-grey" onClick={() => handleSubmit("not")}>Não tenho interesse</Button>
              )
            }
          </>
        )
      }


    </div>
    
  )
}
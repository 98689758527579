import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { motion } from 'framer-motion';
import { Home } from "./pages/Home";
import { SignCar } from "./pages/FormSignCar";
import { Chat } from "./pages/Chat";
import { Indicacao } from "./pages/Indicacao";

const Rotas = () => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      transition={{ duration: 0.5 }}
      variants={{
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }}
      style={{ height: '100%' }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/sign-car/:stage" component={SignCar}></Route>
          <Route exact path="/chat" component={Chat}></Route>
          <Route exact path="/indicacao" component={Indicacao}></Route>
        </Switch>
      </Router >
    </motion.div >
  )
};

export default Rotas;
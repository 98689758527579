import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { formatReal } from "../../../helpers";

import { FormLocalizaMeoo } from "./FormsComplementaryInfos/FormLocalizaMeoo";
import { FormUseCar } from "./FormsComplementaryInfos/FormUseCar";
import { FormMatriz } from "./FormsComplementaryInfos/FormMatriz";
import { FormMovida } from "./FormsComplementaryInfos/FormMovida";
import { FormCarroFacil } from "./FormsComplementaryInfos/FormCarroFacil";

import { SIGN_CAR_STEPS } from "../../../@enums/index";
import { FormByecarOriginals } from "./FormsComplementaryInfos/FormByecarOriginals";
import CustomCheckSwitch from "../../../components/CustomCheckSwitch";
import { FormAssineCarLM } from "./FormsComplementaryInfos/FormAssineCarLM";
import { FormUnidasLivre } from "./FormsComplementaryInfos/FormUnidasLivre";


export const ComplementaryInfos = (props: any): JSX.Element => {
  const { signData, setSignData, nextStep,
     locadorasParams, user, setRequestId, handleSwitch,
     contactRequestedShowMessage, setContactRequestedShowMessage } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (signData.contactRequested) {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set('contact_req', '1');

      const queryString = urlParams.toString();
      const url = window.location.href.split('?')[0];
      const separator = url.includes('?') ? '&' : '?';
      const updatedUrl = `${url}${separator}${queryString}`;

      window.history.pushState(null, '', updatedUrl);
    }

  }, []);

  const handleSetLoading = (state: boolean) => {
    setLoading(state);
  };

  const handleClickBack = () => {
    const prev: number = SIGN_CAR_STEPS.modelQuote;
    history.push(`/sign-car/cotacao`);
    nextStep(prev);
  };

  const handleForm = () => {
    switch (signData.locadoraId) {
      case 1:
        return (
          <FormLocalizaMeoo
            handleClickBack={handleClickBack}
            nextStep={nextStep}
            signData={signData}
            locadorasParams={locadorasParams}
            setLoading={handleSetLoading}
            loading={loading}
            user={user}
            setRequestId={setRequestId}
          />
        );
      case 2:
        return (
          <FormMovida
            handleClickBack={handleClickBack}
            nextStep={nextStep}
            signData={signData}
            locadorasParams={locadorasParams}
            setLoading={handleSetLoading}
            loading={loading}
            user={user}
            setRequestId={setRequestId}
          />
        );
      case 3:
        return (
          <FormCarroFacil
            handleClickBack={handleClickBack}
            nextStep={nextStep}
            signData={signData}
            locadorasParams={locadorasParams}
            setLoading={handleSetLoading}
            loading={loading}
            user={user}
            setRequestId={setRequestId}
          />
        );
      case 4:
        return (
          <FormUseCar
            handleClickBack={handleClickBack}
            nextStep={nextStep}
            signData={signData}
            locadorasParams={locadorasParams}
            setLoading={handleSetLoading}
            loading={loading}
            user={user}
            setRequestId={setRequestId}
          />
        );
      case 7:
        return (
          <FormMatriz
            handleClickBack={handleClickBack}
            nextStep={nextStep}
            signData={signData}
            locadorasParams={locadorasParams}
            setLoading={handleSetLoading}
            loading={loading}
            user={user}
            setRequestId={setRequestId}
          />
        );
        case 8:
          return (
            <FormByecarOriginals
              handleClickBack={handleClickBack}
              nextStep={nextStep}
              signData={signData}
              locadorasParams={locadorasParams}
              setLoading={handleSetLoading}
              loading={loading}
              user={user}
              setRequestId={setRequestId}
            />
          );
        case 9:
          return (
            <FormAssineCarLM
              handleClickBack={handleClickBack}
              nextStep={nextStep}
              signData={signData}
              locadorasParams={locadorasParams}
              setLoading={handleSetLoading}
              loading={loading}
              user={user}
              setRequestId={setRequestId}
            />
          );
        case 10:
          return (
            <FormUnidasLivre
              handleClickBack={handleClickBack}
              nextStep={nextStep}
              signData={signData}
              locadorasParams={locadorasParams}
              setLoading={handleSetLoading}
              loading={loading}
              user={user}
              setRequestId={setRequestId}
            />
          )
    }
  };

  return (
    <div>
      <div
        className={loading ? "loading-container" : "d-none"}
        style={{ alignItems: "start" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
      <div className={loading ? "d-none" : "title-card-car-details"}>
        {signData.selectedCar.veiculo.nome}
      </div>
      <Card className={loading ? "d-none" : "card-car-details"}>
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <h6>
                Franquia de KM: <b>{signData.franchise}</b>
              </h6>
              <h6>
                Prazo de entrega:{" "}
                <b>{signData.selectedCar.prazo_entrega_desc}</b>
              </h6>
              <h6>
                Prazo do contrato (em meses):{" "}
                <b>{signData.selectedCar.contractTime}</b>
              </h6>
              <h6>
                Valor mensal: <b>R$ {formatReal(signData.selectedCar.price)}</b>
              </h6>
              <em
                style={{
                  fontSize: "12px",
                }}
              >
                {signData.selectedCar.displayPriceInfo}
              </em>
            </Col>
            <Col md={6} className="text-center">
              <img
                width="60%"
                src={signData.selectedCar.veiculo.url_foto}
                alt=""
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className={loading ? "d-none" : ""}>
        {
          parseInt(process.env.REACT_APP_SHOW_CONTACT_REQUESTED) === 1 && 
          contactRequestedShowMessage &&
          (
            <Col xs={12} className="mb-3">
              <CustomCheckSwitch 
                  contactRequestedShowMessage={contactRequestedShowMessage}
                  setContactRequestedShowMessage={setContactRequestedShowMessage}
                  contactRequested={signData.contactRequested ?? false}
                  bgColor="#4DBCE9" handleSwitch={handleSwitch} />
            </Col>
          )
        } 
        
      </Row>

      {handleForm()}
    </div>
  );
};

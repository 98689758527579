export const listarProfissoes = (locadorasParams: any, locadoraId: number) => {
  let profissoes: any = [];
  const {locadoras} = locadorasParams;
  locadoras.forEach((locadora: any) => {
    if(locadora.locadora_id === locadoraId){
      locadora.parametros_pedidos.categoria_profissional.forEach((categoria: any) => {
        profissoes[categoria.id] = {label: categoria.nome, value: categoria.id};
      });
    }
  });
  return profissoes;
}

export const listarCargos = (locadorasParams: any, locadoraId: number) => {
  let cargos: any = [];
  const {locadoras} = locadorasParams;
  locadoras.forEach((locadora: any) => {
    if(locadora.locadora_id === locadoraId){
      locadora.parametros_pedidos.atividade_profissional.forEach((cargo: any) => {
        cargos[cargo.id] = {label: cargo.name, value: cargo.id};
      });
    }
  });
  return cargos;
}

export const listarFormasDePagamento = (locadorasParams: any, locadoraId: number) => {
  let formasDePagamento: any = [];
  const {locadoras} = locadorasParams;
  locadoras.forEach((locadora: any) => {
    if(locadora.locadora_id === locadoraId){
      locadora.parametros_pedidos.forma_pagamento.forEach((categoria: any) => {
        formasDePagamento[categoria.id] = {label: categoria.name, value: categoria.id};
      });
    }
  });
  return formasDePagamento;
}

export const listarOpcionais = (locadorasParams: any, locadoraId: number) => {
  let opcionais: any = [];
  const {locadoras} = locadorasParams;
  locadoras.forEach((locadora: any) => {
    if (locadora.locadora_id === locadoraId) {
      locadora.parametros_pedidos.opcionais.forEach((opcional: any) => {
        let obj = { label: opcional.name, value: opcional.id }
        opcionais.push(obj);
      });
    }
  });
  return opcionais;
}

export const listarCores = (arrayCores: any[]) => {
  let cores: any = [];
  arrayCores.forEach((cor: any) => {
    let obj = { label: cor.descricao_cor, value: cor.codigo_cor }
    cores.push(obj);    
  });
  return cores;
}
import { Card } from 'react-bootstrap';
import { CategoriesCarousel } from "../../../models/Carousel";

export const CategoriesType = ({ categoryName, categoryImage, slug, handleSlug, categorySelected, setCategorySelected }: CategoriesCarousel): JSX.Element => {
  return (
    <Card className={`d-flex justify-content-center card-categories text-center cursor-pointer ${categorySelected === slug ? 'card-categories-active' : ''}`} onClick={() => {handleSlug(slug); setCategorySelected(slug)}}>
      <p><b>{categoryName.toUpperCase()}</b></p>
      <img className="img-category" src={categoryImage} alt=""/>
    </Card>
  );
};
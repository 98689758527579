import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SIGN_CAR_STEPS } from "../../../../@enums/index";
import { optionsCivilState } from "../../../../@options";
import { cpfValido } from "../../../../helpers";
import 'dotenv/config';
import { useHistory } from "react-router-dom";

//components
import ReactInputMask from "react-input-mask";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import Select from 'react-select';
import { Error } from "../../../../components/Error";

//services
import { getStatesList } from "../../../../services/signCar.service";
import { savePedido } from "../../../../services/submitForms.service";
import { listarCores, listarOpcionais } from "../../../../services/locadorasParams.service";
import { toast } from "react-toastify";

export const FormUseCar = (props: any): JSX.Element => {
  const { nextStep, locadorasParams, signData, loading, 
    setLoading, user, setRequestId, handleClickBack } = props;
  const [showFormPessoaJuridica, setShowFormPessoaJuridica] = useState<boolean>(signData.contractType === '1' ? false : true);
  const [listOptionals, setListOptionals] = useState([]);
  const [optionalsSelected, setOptionalsSelected] = useState([]);

  const validationSchema = Yup.object({
    dataNascimento: !showFormPessoaJuridica ? Yup.string().required('A data de nascimento é obrigatória') : Yup.string(),
    estadoCivil: !showFormPessoaJuridica ? Yup.object().required('O estado civil é obrigatório') : Yup.object(),
    cnh: !showFormPessoaJuridica ? Yup.string().required('A CNH é obrigatória') : Yup.string(),
    dataEmissaoCnh: !showFormPessoaJuridica ? Yup.string().required('A data de emissão é obrigatória') : Yup.string(),
    dataValidadeCnh: !showFormPessoaJuridica ? Yup.string().required('A data de validade é obrigatória') : Yup.string(),
    logradouro: !showFormPessoaJuridica ? Yup.string().required('O logradouro é obrigatório') : Yup.string(),
    numero: Yup.string(),
    complemento: Yup.string(),
    bairro: !showFormPessoaJuridica ? Yup.string().required('O bairro é obrigatório') : Yup.string(),
    cidade: !showFormPessoaJuridica ? Yup.string().required('A cidade é obrigatória') : Yup.string(),
    estado: !showFormPessoaJuridica ? Yup.object().required('O estado é obrigatório') : Yup.object(),
    cnhFile: !showFormPessoaJuridica ? Yup.array().required('O upload da CNH é obrigatório') : Yup.array(),
    comprovanteEnderecoFile: !showFormPessoaJuridica ? Yup.array().required('O upload do comprovante de endereço é obrigatório') : Yup.array(),
    comprovanteRendaFile: !showFormPessoaJuridica ? Yup.array().required('O upload do comprovante de renda é obrigatório') : Yup.array(),
    faturaCartaoCredito: !showFormPessoaJuridica ? Yup.array().required('O upload da fatura do cartão de crédito é obrigatório') : Yup.array(),
    razaoSocial: showFormPessoaJuridica ? Yup.string().required('A razão social é obrigatória') : Yup.string(),
    cnpj: showFormPessoaJuridica ? Yup.string().min(18, "Muito curto").max(18, "Muito longo").required('O CNPJ é obrigatório') : Yup.string(),
    nomeSocio: showFormPessoaJuridica ? Yup.string().required('O nome do sócio é obrigatório') : Yup.string(),
    cpfSocio: showFormPessoaJuridica ? Yup.string().test("test-cpf", "Informe um CPF válido", function(value){
      const { path, createError } = this;
      value = value.replace(/[^\d]+/g, '');
      return (
        cpfValido(value) ||
        createError({ path, message: "Informe um CPF válido" })
      );
    }).required('O CPF do sócio é obrigatório') : Yup.string(),
    emailSocio: showFormPessoaJuridica ? Yup.string().email('E-mail inválido').required('O e-mail do sócio é obrigatório') : Yup.string(),
    celularSocio: showFormPessoaJuridica ? Yup.string().required('O celular do sócio é obrigatório') : Yup.string(),
    cnhSocio: showFormPessoaJuridica ? Yup.string().required('O número da CNH é obrigatório') : Yup.string(),
    dataEmissaoCnhSocio: showFormPessoaJuridica ? Yup.string().required('A data de emissão é obrigatória') : Yup.string(),
    dataValidadeCnhSocio: showFormPessoaJuridica ? Yup.string().required('A data de validade é obrigatória') : Yup.string(),
    cepPessoaJuridica: showFormPessoaJuridica ? Yup.string().min(9, "Muito curto").max(9, "Muito longo").required('O CEP da empresa é obrigatório') : Yup.string(),
    logradouroPessoaJuridica: showFormPessoaJuridica ? Yup.string().required('O logradouro é obrigatório') : Yup.string(),
    numeroPessoaJuridica: Yup.string(),
    complementoPessoaJuridica: Yup.string(),
    bairroPessoaJuridica: showFormPessoaJuridica ? Yup.string().required('O bairro é obrigatório') : Yup.string(),
    cidadePessoaJuridica: showFormPessoaJuridica ? Yup.string().required('A cidade é obrigatória') : Yup.string(),
    estadoPessoaJuridica: showFormPessoaJuridica ? Yup.object().required('O estado é obrigatório') : Yup.object(),
    cnhFileSocio: showFormPessoaJuridica ? Yup.array().required('O upload da CNH é obrigatório') : Yup.array(),
    comprovanteEnderecoFileSocio: showFormPessoaJuridica ? Yup.array().required('O upload do comprovante de endereço é obrigatório') : Yup.array(),
    comprovanteRendaFileSocio: showFormPessoaJuridica ? Yup.array().required('O upload do comprovante de renda é obrigatório') : Yup.array(),
    contratoSocial: showFormPessoaJuridica ? Yup.array().required('O upload do contrato social é obrigatório') : Yup.array(),
    cartaoCnpj: showFormPessoaJuridica ? Yup.array().required('O upload do cartã CNPJ é obrigatório') : Yup.array(),
  }).required();

  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
  const history = useHistory()
  
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [optionStates, setOptionStates] = useState([]);
  const [colors, setColors] = useState([]);

  const onSubmit = (formData: any) => {
    setLoadingSubmit(true);
    
    let objSubmit = {
      pedido: {
        locadora_id: signData.locadoraId,
        veiculo_id: signData.selectedCar.veiculo_id,
        tempo_contrato: signData.selectedCar.contractTime,
        franquia_km: signData.franchise,
        tipo_contrato: signData.contractType,
        forma_pagamento: signData.payWay,
        opcionais: optionalsSelected,
        cor_id: formData.corCarro.value ?? "",
      },
      cliente: {
        lead_id: signData.lead_id,
        razao_social: showFormPessoaJuridica ? formData.razaoSocial : "",
        num_cnpj: showFormPessoaJuridica ? formData.cnpj : "",
        nome_completo: showFormPessoaJuridica ? formData.nomeSocio : user.name,
        num_cpf: showFormPessoaJuridica ? formData.cpfSocio : user.cpf,
        email: showFormPessoaJuridica ? formData.emailSocio : user.email,
        num_celular: showFormPessoaJuridica ? formData.celularSocio : user.celular,
        data_nascimento: formData.dataNascimento ?? "",
        estado_civil_id: formData.estadoCivil && formData.estadoCivil.value ? formData.estadoCivil.value : "",
        num_cnh: showFormPessoaJuridica ? formData.cnhSocio : formData.cnh,
        data_emissao_cnh: showFormPessoaJuridica ? formData.dataEmissaoCnhSocio : formData.dataEmissaoCnh,
        data_validade_cnh: showFormPessoaJuridica ? formData.dataValidadeCnhSocio : formData.dataValidadeCnh,
        endereco: {
          num_cep: showFormPessoaJuridica ? formData.cepPessoaJuridica : user.cep,
          logradouro: showFormPessoaJuridica ? formData.logradouroPessoaJuridica : formData.logradouro,
          numero: showFormPessoaJuridica ? formData.numeroPessoaJuridica : formData.numero,
          complemento: showFormPessoaJuridica ? formData.complementoPessoaJuridica : formData.complemento,
          bairro: showFormPessoaJuridica ? formData.bairroPessoaJuridica : formData.bairro,
          cidade: showFormPessoaJuridica ? formData.cidadePessoaJuridica : formData.cidade,
          sigla_estado: showFormPessoaJuridica ? formData.estadoPessoaJuridica.value : formData.estado.value
        }        
      },
      url_cnh: showFormPessoaJuridica ? formData.cnhFileSocio[0] : formData.cnhFile[0],
      url_comprovante_renda: showFormPessoaJuridica ? formData.comprovanteRendaFileSocio[0] : formData.comprovanteRendaFile[0],
      url_fatura_cartao_credito: showFormPessoaJuridica ? null : formData.faturaCartaoCredito[0],
      url_comprovante_endereco: showFormPessoaJuridica ? formData.comprovanteEnderecoFileSocio[0] : formData.comprovanteEnderecoFile[0],
      url_contrato_social: showFormPessoaJuridica ? formData.contratoSocial[0] : null,
      url_cartao_cnpj: showFormPessoaJuridica ? formData.cartaoCnpj[0] : null,
    }
    
    savePedido(objSubmit).then((response) => {
      setRequestId(response.order)
      const tagSemPararStep = process.env.REACT_APP_SHOW_TAGSEMPARAR_STEP;
      nextStep((parseInt(tagSemPararStep) === 1 ? 
        SIGN_CAR_STEPS.tagSemParar : 
        SIGN_CAR_STEPS.successSign));
      setLoadingSubmit(false);
      history.push(`/sign-car/sem-parar`)
    }).catch((err) => {
      setLoadingSubmit(false);
      toast.error(err.response.data)
    })
  }

  useEffect(() => {
    setListOptionals(listarOpcionais(locadorasParams, signData.locadoraId));
    setColors(listarCores(JSON.parse(signData.selectedCar.json_cores)));
    getStatesList().then((response) => {
      let userStateSelect = ""
      setOptionStates(response.map((estado: any) => {
        if (estado.sigla === user.address.estado) {
          userStateSelect = estado.nome + ' - ' + estado.sigla;
        }
        return {
          label: estado.nome + ' - ' + estado.sigla,
          value: estado.sigla,
        }
      }));
      if (showFormPessoaJuridica) {
        setValue('estadoPessoaJuridica', { value: user.address.estado, label: userStateSelect })
      } else {
        setValue('estado', { value: user.address.estado, label: userStateSelect })
      }
      setLoading(false);
    });
  },[])
  
  return (
    <div className={loading ? 'd-none' : 'mt-5'}>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        {
          (colors.length > 0 || listOptionals.length > 0) &&
          <h5>Personalize seu contrato</h5>
        }
        <Row>
          {
            colors.length > 0 &&
            <Col md={12} className="mb-2">
              <Controller
                name="corCarro"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Cores:</Form.Label>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={colors}
                      placeholder="Selecione..."
                      defaultValue=""
                      />
                  </Form.Group>
                )}
              />
            </Col>
          }

          {
            listOptionals.length > 0 &&
            <Col xs={12} className="mb-2">
              <Form.Label>Opcionais:</Form.Label><br/>
              {
                listOptionals.map((optional, index) => (
                  <Form.Check
                    key={index}
                    inline
                    label={optional.label}
                    type="checkbox"
                    id={optional.value}
                    onChange={() => {
                      if(!optionalsSelected.includes(optional.value)) {
                        setOptionalsSelected([...optionalsSelected, optional.value])
                      } else {
                        let arr = optionalsSelected;
                        setOptionalsSelected(arr.filter(x => x !== optional.value))
                      }
                    }}
                  />
                ))
              }
            </Col>
          }
          
        </Row>

        <h5 className="mt-4">Agora precisamos dos últimos detalhes da sua assinatura</h5>
        <Row>
          {
            !showFormPessoaJuridica ? (
              <>
              <Col xs={12} className="mb-2">
                  <Controller
                    name="cpf"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactInputMask mask="999.999.999-99"
                        value={user.cpf}
                        readOnly={true}
                        onChange={field.onChange}
                      >
                        {(inputProps: any) => (
                          <Form.Group>
                            <Form.Label>CPF:</Form.Label>
                            <Form.Control
                              defaultValue=""
                              {...inputProps}
                              readOnly
                              placeholder="Digite seu CPF" />
                          </Form.Group>
                        )}
                      </ReactInputMask>
                    )}
                  />
                  {errors.cpf ? (<div className="w-100"> <Error>{errors.cpf?.message}</Error> </div>) : ""}
                </Col>
                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Data de Nascimento:</Form.Label>
                    <Form.Control 
                      type="date"
                      min="1900-01-01"
                      max="3000-12-31"
                      {...register("dataNascimento", { required: true })}
                      placeholder="Digite a sua data de nascimento"/>
                  </Form.Group>
                  {errors.dataNascimento ? (<div className="w-100"> <Error>{errors.dataNascimento?.message}</Error> </div>) : ""}
                </Col>

                <Col md={6} className="mb-2">
                  <Controller
                    name="estadoCivil"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Group>
                        <Form.Label>Estado Civil:</Form.Label>
                        <Select
                          {...field}
                          isSearchable={false}
                          options={optionsCivilState}
                          placeholder="Selecione..."
                          />
                      </Form.Group>
                    )}
                  />
                  {errors.estadoCivil ? (<div className="w-100"> <Error>{errors.estadoCivil?.message}</Error> </div>) : ""}
                </Col>

                <Col md={12} className="mb-2">
                  <Form.Group>
                    <Form.Label>CNH:</Form.Label>
                    <Form.Control 
                      type="text"
                      maxLength={11}
                      placeholder="Digite sua CNH"
                      {...register("cnh", { required: true })}/>
                      {errors.cnh ? (<div className="w-100"> <Error>{errors.cnh?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Data de Emissão da CNH:</Form.Label>
                    <Form.Control 
                      type="date"
                      min="1900-01-01"
                      max="3000-12-31"
                      {...register("dataEmissaoCnh", { required: true })}
                      placeholder="Digite a data de emissão"/>
                  </Form.Group>
                  {errors.dataEmissaoCnh ? (<div className="w-100"> <Error>{errors.dataEmissaoCnh?.message}</Error> </div>) : ""}
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Data de Validade da CNH:</Form.Label>
                    <Form.Control 
                      type="date"
                      min="1900-01-01"
                      max="3000-12-31"
                      {...register("dataValidadeCnh", { required: true })}
                      placeholder="Digite a data de validade"/>
                  </Form.Group>
                  {errors.dataValidadeCnh ? (<div className="w-100"> <Error>{errors.dataValidadeCnh?.message}</Error> </div>) : ""}
                </Col>

                {/* endereço */}
                <Col md={9} className="mb-2">
                  <Form.Group>
                    <Form.Label>Logradouro:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Logradouro"
                      defaultValue={user && user.address.logradouro ? user.address.logradouro : ""}
                      {...register("logradouro", { required: true })}/>
                      {errors.logradouro ? (<div className="w-100"> <Error>{errors.logradouro?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={3} className="mb-2">
                  <Form.Group>
                    <Form.Label>Número:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Nº"
                      {...register("numero")}/>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mb-2">
                  <Form.Group>
                    <Form.Label>Complemento:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Complemento"
                      {...register("complemento")}/>
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Form.Group>
                    <Form.Label>Bairro:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Digite o bairro"
                      defaultValue={user && user.address.bairro ? user.address.bairro : ""}
                      {...register("bairro", { required: true })}/>
                      {errors.bairro ? (<div className="w-100"> <Error>{errors.bairro?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Form.Group>
                    <Form.Label>Cidade:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Digite a cidade"
                      defaultValue={user && user.address.cidade ? user.address.cidade : ""}
                      {...register("cidade", { required: true })}/>
                      {errors.cidade ? (<div className="w-100"> <Error>{errors.cidade?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Controller
                    name="estado"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Group>
                        <Form.Label>Estado:</Form.Label>
                        <Select
                          {...field}
                          isSearchable={false}
                          options={optionStates}
                          placeholder="Selecione..."
                          />
                      </Form.Group>
                    )}
                  />
                  {errors.estado ? (<div className="w-100"> <Error>{errors.estado?.message}</Error> </div>) : ""}
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Razão Social:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Razão Social"
                      {...register("razaoSocial", { required: true })}/>
                      {errors.razaoSocial ? (<div className="w-100"> <Error>{errors.razaoSocial?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  <Controller
                    name="cnpj"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactInputMask mask="99.999.999/9999-99" 
                        value={field.value}
                        onChange={field.onChange}
                      >
                      {(inputProps: any) => (
                        <Form.Group>
                          <Form.Label>CNPJ:</Form.Label>
                          <Form.Control 
                            {...inputProps}
                            placeholder="Digite o CNPJ"/>
                        </Form.Group>
                      )}
                    </ReactInputMask>
                    )}
                  />
                  {errors.cnpj ? (<div className="w-100"> <Error>{errors.cnpj?.message}</Error> </div>) : ""}
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Nome do Sócio:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Nome do Sócio"
                      {...register("nomeSocio", { required: true })}/>
                      {errors.nomeSocio ? (<div className="w-100"> <Error>{errors.nomeSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  <Controller
                    name="cpfSocio"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactInputMask mask="999.999.999-99" 
                        value={field.value}
                        onChange={field.onChange}
                      >
                      {(inputProps: any) => (
                        <Form.Group>
                          <Form.Label>CPF do Sócio:</Form.Label>
                          <Form.Control 
                            {...inputProps}
                            placeholder="Digite seu CPF"/>
                        </Form.Group>
                      )}
                    </ReactInputMask>
                    )}
                  />
                  {errors.cpfSocio ? (<div className="w-100"> <Error>{errors.cpfSocio?.message}</Error> </div>) : ""}
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>E-mail do Sócio:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="E-mail"
                      {...register("emailSocio", { required: true })}/>
                      {errors.emailSocio ? (<div className="w-100"> <Error>{errors.emailSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  <Controller
                    name="celularSocio"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactInputMask mask="(99) 99999-9999" 
                        value={field.value}
                        onChange={field.onChange}
                      >
                      {(inputProps: any) => (
                        <Form.Group>
                          <Form.Label>Celular do Sócio:</Form.Label>
                          <Form.Control 
                            {...inputProps}
                            placeholder="Digite o celular"/>
                        </Form.Group>
                      )}
                    </ReactInputMask>
                    )}
                  />
                  {errors.celularSocio ? (<div className="w-100"> <Error>{errors.celularSocio?.message}</Error> </div>) : ""}
                </Col>

                <Col md={12} className="mb-2">
                  <Form.Group>
                    <Form.Label>CNH do Sócio ou do condutor:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Digite a CNH"
                      maxLength={11}
                      {...register("cnhSocio", { required: true })}/>
                      {errors.cnhSocio ? (<div className="w-100"> <Error>{errors.cnhSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Data de Emissão da CNH:</Form.Label>
                    <Form.Control 
                      type="date"
                      min="1900-01-01"
                      max="3000-12-31"
                      {...register("dataEmissaoCnhSocio", { required: true })}
                      placeholder="Digite a data de emissão"/>
                  </Form.Group>
                  {errors.dataEmissaoCnhSocio ? (<div className="w-100"> <Error>{errors.dataEmissaoCnhSocio?.message}</Error> </div>) : ""}
                </Col>

                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label>Data de Validade da CNH:</Form.Label>
                    <Form.Control 
                      type="date"
                      min="1900-01-01"
                      max="3000-12-31"
                      {...register("dataValidadeCnhSocio", { required: true })}
                      placeholder="Digite a data de validade"/>
                  </Form.Group>
                  {errors.dataValidadeCnhSocio ? (<div className="w-100"> <Error>{errors.dataValidadeCnhSocio?.message}</Error> </div>) : ""}
                </Col>

                {/* endereço */}
                <Col md={12} className="mb-2">
                  <Controller
                    name="cepPessoaJuridica"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={user && user.cep ? user.cep : ""}
                    render={({ field }) => (
                      <ReactInputMask mask="99999-999" 
                        value={field.value}
                        onChange={field.onChange}
                      >
                      {(inputProps: any) => (
                        <Form.Group>
                          <Form.Label>CEP:</Form.Label>
                          <Form.Control 
                            {...inputProps}
                            placeholder="Digite o CEP"/>
                        </Form.Group>
                      )}
                    </ReactInputMask>
                    )}
                  />
                  {errors.cepPessoaJuridica ? (<div className="w-100"> <Error>{errors.cepPessoaJuridica?.message}</Error> </div>) : ""}
                </Col>

                <Col md={9} className="mb-2">
                  <Form.Group>
                    <Form.Label>Logradouro:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Logradouro"
                      defaultValue={user && user.address.logradouro ? user.address.logradouro : ""}
                      {...register("logradouroPessoaJuridica", { required: true })}/>
                      {errors.logradouroPessoaJuridica ? (<div className="w-100"> <Error>{errors.logradouroPessoaJuridica?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={3} className="mb-2">
                  <Form.Group>
                    <Form.Label>Número:</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Nº"
                      {...register("numeroPessoaJuridica")}/>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mb-2">
                  <Form.Group>
                    <Form.Label>Complemento:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Complemento"
                      {...register("complementoPessoaJuridica")}/>
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Form.Group>
                    <Form.Label>Bairro:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Digite o bairro"
                      defaultValue={user && user.address.bairro ? user.address.bairro : ""}
                      {...register("bairroPessoaJuridica", { required: true })}/>
                      {errors.bairroPessoaJuridica ? (<div className="w-100"> <Error>{errors.bairroPessoaJuridica?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Form.Group>
                    <Form.Label>Cidade:</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Digite a cidade"
                      defaultValue={user && user.address.cidade ? user.address.cidade : ""}
                      {...register("cidadePessoaJuridica", { required: true })}/>
                      {errors.cidadePessoaJuridica ? (<div className="w-100"> <Error>{errors.cidadePessoaJuridica?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-2">
                  <Controller
                    name="estadoPessoaJuridica"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Group>
                        <Form.Label>Estado:</Form.Label>
                        <Select
                          {...field}
                          isSearchable={false}
                          options={optionStates}
                          placeholder="Selecione..."
                          />
                      </Form.Group>
                    )}
                  />
                  {errors.estadoPessoaJuridica ? (<div className="w-100"> <Error>{errors.estadoPessoaJuridica?.message}</Error> </div>) : ""}
                </Col>
              </>
            )
          }
        </Row>

        <h5 className="mt-5">Upload de Arquivos</h5>
        <hr style={{color: 'white'}}/>
        <Row>
          {
            !showFormPessoaJuridica ? (
              <>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>CNH</Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('cnhFile', [e.currentTarget.files])}/>
                      {errors.cnhFile ? (<div className="w-100"> <Error>{errors.cnhFile?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Comprovante de Endereço<br/>
                      <small><i>Conta de consumo de até 90 dias em nome do titular</i></small>
                    </Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('comprovanteEnderecoFile', [e.currentTarget.files])} />
                    {errors.comprovanteEnderecoFile ? (<div className="w-100"> <Error>{errors.comprovanteEnderecoFile?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Comprovante de Renda<br/>
                      <small><i>3 últimos extratos bancários ou holerites em nome do titular</i></small>

                    </Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('comprovanteRendaFile', [e.currentTarget.files])} />
                    {errors.comprovanteRendaFile ? (<div className="w-100"> <Error>{errors.comprovanteRendaFile?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Última fatura do cartão de crédito</Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('faturaCartaoCredito', [e.currentTarget.files])} />
                    {errors.faturaCartaoCredito ? (<div className="w-100"> <Error>{errors.faturaCartaoCredito?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
              </>
            ) : (
              <>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>CNH do Sócio</Form.Label>
                    <Form.Control type="file" multiple accept=".jpg,.jpeg,.pdf,.png"
                      onChange={(e: any) => setValue('cnhFileSocio', [e.currentTarget.files])}/>
                      {errors.cnhFileSocio ? (<div className="w-100"> <Error>{errors.cnhFileSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Contrato Social</Form.Label>
                    <Form.Control type="file" multiple accept=".pdf"
                      onChange={(e: any) => setValue('contratoSocial', [e.currentTarget.files])}/>
                      {errors.contratoSocial ? (<div className="w-100"> <Error>{errors.contratoSocial?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Cartão CNPJ</Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('cartaoCnpj', [e.currentTarget.files])}/>
                      {errors.cartaoCnpj ? (<div className="w-100"> <Error>{errors.cartaoCnpj?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Comprovante de Endereço <br/>
                      <small><i>Conta de consumo de até 90 dias em nome da empresa</i></small>
                    </Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('comprovanteEnderecoFileSocio', [e.currentTarget.files])} />
                    {errors.comprovanteEnderecoFileSocio ? (<div className="w-100"> <Error>{errors.comprovanteEnderecoFileSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Comprovante de Renda <br/>
                      <small><i>3 últimos extratos bancários da empresa</i></small>
                    </Form.Label>
                    <Form.Control type="file" multiple accept="image/*,.pdf"
                      onChange={(e: any) => setValue('comprovanteRendaFileSocio', [e.currentTarget.files])} />
                    {errors.comprovanteRendaFileSocio ? (<div className="w-100"> <Error>{errors.comprovanteRendaFileSocio?.message}</Error> </div>) : ""}
                  </Form.Group>
                </Col>
              </>
            )
          }
          

          <Col md={6} className="mt-5 mb-5 d-grid gap-2 mx-auto">
            {
              loadingSubmit ? (
                <div className="d-flex justify-content-center mb-3">
                  <Spinner animation="border" variant="primary" className="spinner-button" />
                </div>
              ) : (
                <Button type="submit" className="w-100 mb-3">Concluir</Button>
              )
            }
            <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
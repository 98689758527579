import api from './api';


export const createLead = async (data: any) => {
  const params = {
    name: data.name,
    cpf_cnpj: data.cpf,
    email: data.email,
    phone: data.celular,
    postal_code: data.cep,
    goal_to_use_for: data.utilCar.value,
    origin: process.env.REACT_APP_NAME,
    utm_source: data.utmParams?.utmSource,
    utm_medium: data.utmParams?.utmMedium,
    utm_campaign: data.utmParams?.utmCampaign,
    utm_convertion: data.utmParams?.utmConvertion
  };
  return await api.post('/site/lead', params);
}


export const updateLead = 
  async (leadId: number, contactRequested: boolean) => {
    return await api.patch(`/site/lead/${leadId}`, {contactRequested});
}
export const SIGN_CAR_STEPS = {
  clientInfo: 0,
  verifyRegister: 1,
  analysisFeedback: 2,
  rentalFilter: 3,
  modelSelection: 4,
  modelQuote: 5,
  complementaryInfos: 6,
  tagSemParar: 7,
  successSign: 8
}

export const FEEDBACK_CODE = {
  error: 0,
  success: 1,
}
export function getTiposContratacao(locadorasParams: any, filter: any) {
  let tipoContrato: any = [];
  let tiposContratos: any = [];
  locadorasParams.forEach((locadora: any) => {
    if(
        filter.consegueComprovarRenda === "sim" || 
        (filter.consegueComprovarRenda === "nao" && 
        locadora.parametros_pedidos.precisa_comprovar_renda === "nao")
      ){
      tiposContratos[locadora.prioridade_locacao] = locadora.parametros_pedidos.tipo_contrato;
    }
  });
  tiposContratos.forEach((tipos: any) => {
    popularArray(tipos, tipoContrato);
  });
  return tipoContrato;
}

export function getFormasPagamento(locadorasParams: any, filter: any) {

  let formasPagamentos: any = [];
  locadorasParams.forEach((locadoras: any) => {
    if(filter.consegueComprovarRenda === "nao" && locadoras.parametros_pedidos.precisa_comprovar_renda === "sim"){
      return;
    }
    if(filter.tipoContratacao !== null){
      const existe = valorExiste(
          locadoras.parametros_pedidos.tipo_contrato, 
          filter.tipoContratacao
        );
      if(existe){
        popularArrayFormasPagamento(
          locadoras.parametros_pedidos.forma_pagamento, 
          formasPagamentos,
          filter.tipoContratacao
          );
      }
    }else{
      popularArray(locadoras.parametros_pedidos.forma_pagamento, formasPagamentos);
    }
  });
  return formasPagamentos;
}

export function getFranquiasKm(locadorasParams: any, filter: any  
  ) {

    let franquiasKm: any = [];
    locadorasParams.forEach((locadoras: any) => {
      if(filter.consegueComprovarRenda === "nao" && locadoras.parametros_pedidos.precisa_comprovar_renda === "sim"){
        return;
      }

      if(filter.tipoContratacao !== null && filter.formaPagamento !== null){
        const existeTipoContrato = valorExiste(
          locadoras.parametros_pedidos.tipo_contrato, filter.tipoContratacao
        );
        const existeFormaPagamento = valorExiste(
          locadoras.parametros_pedidos.forma_pagamento, filter.formaPagamento
        );
        if(existeTipoContrato && existeFormaPagamento){
          popularFranquiaKm(locadoras.franquias_km, franquiasKm);
        }
      }else{
        popularFranquiaKm(locadoras.franquias_km, franquiasKm);
      }
    });
    return franquiasKm;
}

export function getCategorias(locadorasParams: any, filter: any) {
    let categorias: any = [];
    locadorasParams.forEach((locadoras: any) => {
      if(filter.consegueComprovarRenda === "nao" && locadoras.parametros_pedidos.precisa_comprovar_renda === "sim"){
        return;
      }

      if(filter.tipoContratacao !== null && filter.formaPagamento !== null && filter.franquiaKm !== null){
        const existeTipoContrato = valorExiste(
          locadoras.parametros_pedidos.tipo_contrato, filter.tipoContratacao
        );
        const existeFormaPagamento = valorExiste(
          locadoras.parametros_pedidos.forma_pagamento, filter.formaPagamento
        );
        const existeFranquiaKm = valorFranquiaExiste(
          locadoras.franquias_km, filter.franquiaKm
        );
        if(existeTipoContrato && existeFormaPagamento && existeFranquiaKm){
          popularCategoria(locadoras.categorias, categorias);
        }
      }else{
        popularCategoria(locadoras.categorias, categorias);
      }
    });
    return categorias;
}

export function getLocadorasPorPrioridadeLocacao(locadorasParams: any, filter: any , categoria: any
  ) {

    let locadorasPorPrioridadeLocacao: any = [];
    locadorasParams.forEach((locadoras: any) => {
      if(filter.consegueComprovarRenda === "nao" && locadoras.parametros_pedidos.precisa_comprovar_renda === "sim"){
        return;
      }
      const existeTipoContrato = valorExiste(
        locadoras.parametros_pedidos.tipo_contrato, filter.tipoContratacao
      );
      const existeFormaPagamento = valorExiste(
        locadoras.parametros_pedidos.forma_pagamento, filter.formaPagamento
      );
      const existeFranquiaKm = valorFranquiaExiste(
        locadoras.franquias_km, filter.franquiaKm
      );
      const existeCategoria = valorExiste(
        locadoras.categorias, categoria.value
      );

      if(existeTipoContrato && existeFormaPagamento && existeFranquiaKm && existeCategoria){
        locadorasPorPrioridadeLocacao[locadoras.prioridade_locacao] = locadoras.locadora_id;
      }      
    });
    return locadorasPorPrioridadeLocacao;
}

const valorExiste = function(pesquisarEmArray: any, buscarId: number){
  return pesquisarEmArray.findIndex((m: any) => m.id === buscarId) !== -1;
}

const valorFranquiaExiste = function(pesquisarEmArray: any, buscarFranquia: number){
  return pesquisarEmArray.findIndex((m: any) => m.franquia_km === buscarFranquia) !== -1;
}

const popularArrayFormasPagamento = function(percorrerEmArray: any, popularEmArray: any, tipoContratacaoId: any){
  percorrerEmArray.forEach((item: any) => {
    if(item.apenas_tipo_contrato !== undefined && tipoContratacaoId !== item.apenas_tipo_contrato){
      return;
    }
    popularEmArray[item.id] = {id: item.id, name: item.name ?? item.nome};
  });
}

const popularArray = function(percorrerEmArray: any, popularEmArray: any){
  percorrerEmArray.forEach((item: any) => {
    popularEmArray[item.id] = {id: item.id, name: item.name ?? item.nome};
  });
}

const popularFranquiaKm = function(percorrerEmArray: any, popularEmArray: any){
  percorrerEmArray.forEach((item: any) => {
    popularEmArray[item.franquia_km] = {value: item.franquia_km, label: `${item.franquia_km} kms`};
  });
}

const popularCategoria = function(percorrerEmArray: any, popularEmArray: any){
  percorrerEmArray.forEach((item: any) => {
    popularEmArray[item.id] = {value: item.id, label: item.nome}
  });
}
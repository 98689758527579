import { useEffect, useState } from "react";
import {useHistory} from 'react-router-dom';
import { SIGN_CAR_STEPS } from "../../../@enums/index";

//components
import { Row, Col, Button, Accordion, Spinner } from "react-bootstrap";
import {formatReal} from '../../../helpers';

//services
import { getModelsListPlans, budget } from "../../../services/signCar.service";
import CustomCheckSwitch from "../../../components/CustomCheckSwitch";

export const ModelQuote = (props: any): JSX.Element => {
  const { signData, setSignData, user, nextStep, 
    setLocadorasParams, locadorasParams, handleSwitch,
    contactRequestedShowMessage, setContactRequestedShowMessage } = props;

  const [carsList, setCarsList] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSelectCar, setLoadingSelectCar] = useState<boolean>(false);
  const [idCarSelected, setIdCarSelected] = useState<string>("");

  const history = useHistory();

  useEffect(() => {
    if(signData.contactRequested){
      const queryString = '?contact_req=1';
      const url = window.location.href.split('?')[0];
      window.history.pushState(null, '', `${url}${queryString}`);
    }
  }, []);

  const handleSelectCar = async (car: any) => {
    setLoadingSelectCar(true);
    const franquia_preco_locadora = car.franquia_preco_veiculo.filter((f: any) => (
      f.franquia_km === signData.franchise && f.tempo_contrato === car.contractTime
    ));
    let locadoraParamsTemp = locadorasParams;
    locadoraParamsTemp.locadoras.map((locadora: any) => {
      if (locadora.locadora_id === signData.locadoraId && franquia_preco_locadora !== null) {
        const parametros_pedidos = franquia_preco_locadora[0].parametros_pedidos;
        locadora.parametros_pedidos.opcionais = parametros_pedidos ? (JSON.parse(parametros_pedidos)).opcionais : [];
      }
    });
    setLocadorasParams(locadoraParamsTemp);
    setSignData({...signData, selectedCar: car });
    await budget(
      signData.locadoraId, 
      car.veiculo.id, 
      signData.franchise, 
      car.contractTime,
      user.name,
      user.celular.replace(/[^\d]+/g, ''),
      user.email,
      signData.lead_id,
      signData.contractType
    );
    handleClickNext();
    setLoadingSelectCar(false);
  }

  const handleClickNext = () => {
    const next: number = SIGN_CAR_STEPS.complementaryInfos;
    history.push(`/sign-car/pedido/`);
    nextStep(next);
  }
  const handleClickBack = () => {
    const prev: number = SIGN_CAR_STEPS.modelSelection;
    history.push(`/sign-car/categoria/`);
    nextStep(prev);
  }

  useEffect(() => {
    const selectedModels = signData.selectedModels.map((model: any) => model.id);
    getModelsListPlans(signData.locadoraId, selectedModels, signData.franchise, signData.contractType).then((cars: any) => {
      let arr: any = [];
      cars.forEach((car: any, index: number) => {
        const preco = car.franquia_preco_veiculo[0].preco_promocional !== null 
        && car.franquia_preco_veiculo[0].preco_promocional < car.franquia_preco_veiculo[0].preco ?
        car.franquia_preco_veiculo[0].preco_promocional : car.franquia_preco_veiculo[0].preco;

        arr.push(index.toString())
        car['contractTime'] = car.franquia_preco_veiculo[0].tempo_contrato;
        car['price'] = preco;
        car['displayPriceInfo'] = displayPriceInfo(car.franquia_preco_veiculo[0]);
      });
      setActiveKeys(arr)
      setCarsList(cars);
    });
  }, []);

  useEffect(() => {
    if (activeKeys.length > 0) {
      setLoading(false);
    }
  }, [activeKeys]);

  const displayPriceInfo = (franquia_preco_veiculo: any): string | null => {
    const preco_promocional = franquia_preco_veiculo.preco_promocional;
    const preco = franquia_preco_veiculo.preco;
    if(preco_promocional !== null && preco_promocional < preco){
      return `De: R$ ${formatReal(preco)} por R$ ${formatReal(preco_promocional)}. ${franquia_preco_veiculo.descricao_display}`;
    }
    return null;
  }

  const handleFilterContractTimeCar = (car: any, contractTime: string, indexContractTime: number) => {
    let arr = carsList.slice(0);
    let index = arr.findIndex((x: any) => x.veiculo.id === car.veiculo.id);
    let modelSelected = arr.filter(x => x.veiculo.id === car.veiculo.id);
    const preco = car.franquia_preco_veiculo[indexContractTime].preco_promocional !== null 
        && car.franquia_preco_veiculo[indexContractTime].preco_promocional < car.franquia_preco_veiculo[indexContractTime].preco ?
        car.franquia_preco_veiculo[indexContractTime].preco_promocional : car.franquia_preco_veiculo[indexContractTime].preco;

    modelSelected[0].contractTime = contractTime;
    modelSelected[0].price = preco;
    modelSelected[0].displayPriceInfo = displayPriceInfo(car.franquia_preco_veiculo[indexContractTime]);
    arr.splice(index, 1, modelSelected[0]);
    setCarsList(arr);
  }

  if (loading) {
    return (
      <div className="loading-container" style={{ alignItems: 'start' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <div className="mt-5">
      <h5>Aqui estão as cotações para os modelos selecionados</h5>
      {
        parseInt(process.env.REACT_APP_SHOW_CONTACT_REQUESTED) === 1 && 
            contactRequestedShowMessage &&
        (
          <Col xs={12} className="mb-3">
            <CustomCheckSwitch 
                  contactRequestedShowMessage={contactRequestedShowMessage}
                  setContactRequestedShowMessage={setContactRequestedShowMessage}
                  contactRequested={signData.contactRequested ?? false}
                  bgColor="#4DBCE9" handleSwitch={handleSwitch} />
          </Col>
        )
      } 
      
      <Row className="mt-5">
        <Accordion defaultActiveKey={activeKeys} alwaysOpen>
          <Row>
          {
            carsList.map((car: any, index: number) => (
              <Col key={index} xs={12} md={6} className="accordion-modelquote">
                <Accordion.Item eventKey={index.toString()} className="mb-3">
                  <Accordion.Header>{car.veiculo.nome}</Accordion.Header>
                  <Accordion.Body>
                    <div className="text-center mb-3">
                      <img width="40%" src={car.veiculo.url_foto} style={{ marginRight: '5px'}} alt="" />
                    </div>
                    <p className="mb-0">Franquia de KM: <b>{signData.franchise}</b></p>
                    <p className="mb-0">Prazo de entrega: <b>{car.prazo_entrega_desc}</b></p>
                    <p className="mb-0">Prazo do contrato (em meses):</p>
                    <div className="text-center mt-3">
                      {
                        car.franquia_preco_veiculo.map((item: any, index: number) => (
                          <Button key={index} variant={car.contractTime === item.tempo_contrato ? "primary" : "secondary" } className="btn-filter btn-sm" type="button" onClick={() => handleFilterContractTimeCar(car, item.tempo_contrato, index)}>{item.tempo_contrato}</Button>
                        ))
                      }
                    </div>
                    <h4 className="mt-4 text-center"><b>R$ {formatReal(car.price)}/mês</b></h4>
                    <p style={{fontSize: '0.7rem', fontStyle: 'italic'}}>{car.displayPriceInfo}</p>
                    <div className="text-center mt-4 px-4">
                      {
                        loadingSelectCar && car.veiculo.id === idCarSelected ? (
                          <Spinner animation="border" variant="primary" className="spinner-button" />
                        ) : (
                          <Button type="button" className="w-100 mb-3" style={{ height: '40px' }} onClick={() => {setIdCarSelected(car.veiculo.id); handleSelectCar(car)}}>Quero esse!</Button>
                        )
                      }
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Col>
            )
          )}
          </Row>
        </Accordion>

        <Col xs={6} className="d-grid gap-2 mx-auto mt-5 mb-5">
          <Button variant="outline-primary" className="w-100" onClick={() => handleClickBack()}>Voltar</Button>
        </Col>
      </Row>
    </div>
  )
}
import axios from 'axios';
import { toast } from "react-toastify";
import 'dotenv/config';

let api = axios.create({
  baseURL: process.env.REACT_APP_BYECAR_API2,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-ApiKey': process.env.REACT_APP_THEME_APIKEY,
    'X-ApiTheme': process.env.REACT_APP_NAME
  },
});

api.interceptors.response.use((response: any) => {
  if ((response.status === 200 || response.status === 201) && response.data.message) {
    toast.success(response.data.message);
  }
  return response;
}, (error: any) => {
  if (error.response && error.response.data) {
    if (typeof error !== 'undefined') {

      if (error.response.status === 422) {
        const errros = error.response.data.error
        if (errros) {
          Object.entries(errros).forEach((key, value: any) => {
            toast.error(value[0], { autoClose: false });
          });
        }
      }

      if (error.response.status === 401) {
        toast.error(error.response.data.message, { autoClose: false });
      }
      
      if (error.response.status === 403) {
        toast.error(error.response.data.message, { autoClose: 7000 });
      }

      if (error.response.status === 400) {
        toast.error(error.response.data.message, { autoClose: false });
      }

      if (error.response.status === 500) {
        toast.error(error.response.data.message, { autoClose: false });
      }
    }
  }

  return Promise.reject(error)
})

export default api;
import axios from 'axios';
import { toast } from "react-toastify";
import 'dotenv/config';

let api = axios.create({
  baseURL: process.env.REACT_APP_BYECAR_CONSULTA_SERASA_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

api.interceptors.response.use((response: any) => {
  if ((response.status === 200 || response.status === 201) && response.data.message) {
    toast.success(response.data.message);
  }
  return response;
}, (error: any) => {
  if (error.response && error.response.data) {
    if (typeof error !== 'undefined') {

      if (error.response.status === 422) {
        const errros = error.response.data.error
        if (errros) {
          Object.entries(errros).forEach((key, value: any) => {
            toast.error(value[0], { autoClose: false });
          });
        }
      }

      if (error.response.status === 401) {
        toast.error(error.response.data.message, { autoClose: false });
      }
      
      if (error.response.status === 403) {
        toast.error(error.response.data.message, { autoClose: 7000 });
      }

      if (error.response.status === 400) {
        toast.error(error.response.data.message, { autoClose: false });
      }

      if (error.response.status === 500) {
        toast.error(error.response.data.message, { autoClose: false });
      }
    }
  }

  return Promise.reject(error)
});

type TConstulSerasaParams = {
  name: string, email: string, 
  cpf: string, celular: string, 
  cep: string, utilCar: any, utmParams: any,
}

export async function criarConsultaSerasa(data: TConstulSerasaParams): Promise<any>{
  const params = {
    cpfCnpj: data.cpf,
    email: data.email,
    celular: data.celular,
    cep: data.cep,
    finalidadeUtilizacao: data.utilCar.value,
    nome: data.name,
    origemConsulta: process.env.REACT_APP_NAME,
    utmParams: data.utmParams,
  };
  return await api.post('/consulta', params);
}

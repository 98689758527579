import styled from "styled-components";

import * as themes from '../../theme/schema.json';
type ObjectKey = keyof typeof themes.data;
const currentTheme = process.env.REACT_APP_NAME! as ObjectKey;
const colors = themes.data[currentTheme].colors;


export const Footer = styled.footer`
  background: ${colors.mainColors.primary};
  width: 100%;
  z-index: 10;
  padding: 15px
`;
import { useContext, useEffect, useState } from 'react';
import { RiCheckDoubleLine } from 'react-icons/ri'

//images
import banner from "../assets/image/banner.png";
import carsFooter from "../assets/image/cars-footer.png";
import economy from "../assets/image/economy-img.png";
import suv from "../assets/image/suv-img.png";
import pickup from "../assets/image/pickup-img.png";
import sedan from "../assets/image/sedan-img.png";
import executive from "../assets/image/executive-img.png";

import {
  getUrlImgJourneyMobile, 
  getUrlImgJourneyWeb,
  getUrlImgLogoDefault
} from "../services/theme.service";

//utils
import { TestimonialCarousel } from '../models/Carousel';
import UsuarioContext from '../context/userContext/context';
import useWindowDimensions from '../hooks/useWindowDimensions';
import {getUtmParams} from '../services/utmConfig.service';

//componentns
import { Col, Row, Button, Accordion, Spinner, Placeholder, Card } from 'react-bootstrap';
import { Carousels } from '../components/Carousels';
import { CarouselPromotion } from '../components/CarouselPromotion';
import { Footer } from '../components/Footer';

// services
import { getCategoriesPromotionCars, getPromotionCars } from '../services/home.service'

export const Home = (): JSX.Element => {
  const { update } = useContext(UsuarioContext);
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const urlParams = getUtmParams();

  const [categories, setCategories] = useState([]);
  const [filteredCarsPromotion, setFilteredCarsPromotion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [isJourneyMobile, setIsJourneyMobile] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const useParamsContext = {token: "", utmParams: {}};
    if(token !== null){
      useParamsContext.token = token;
    }
    if(urlParams !== null){
      useParamsContext.utmParams = urlParams;
    }
    update(useParamsContext);

    getPromotionCars().then((response) => {
      let categories: any = [];
      let categoriesAdded: any = [];

      response.map((car: any) => {
        switch (car.slug_categoria) {
          case 'economico':
            if (!categoriesAdded.includes(car.slug_categoria)) {
              categories.push({nome: car.nome_categoria, slug: car.slug_categoria, image: economy});
              categoriesAdded.push(car.slug_categoria)
            }
            break;
          case 'suv':
            if (!categoriesAdded.includes(car.slug_categoria)) {
              categories.push({nome: car.nome_categoria, slug: car.slug_categoria, image: suv});
              categoriesAdded.push(car.slug_categoria)
            }
            break;
          case 'caminhonete':
            if (!categoriesAdded.includes(car.slug_categoria)) {
              categories.push({nome: car.nome_categoria, slug: car.slug_categoria, image: pickup});
              categoriesAdded.push(car.slug_categoria)
            }
            break;
          case 'executivo':
            if (!categoriesAdded.includes(car.slug_categoria)) {
              categories.push({nome: car.nome_categoria, slug: car.slug_categoria, image: executive});
              categoriesAdded.push(car.slug_categoria)
            }
            break;
          case 'sedan':
            if (!categoriesAdded.includes(car.slug_categoria)) {
              categories.push({nome: car.nome_categoria, slug: car.slug_categoria, image: sedan});
              categoriesAdded.push(car.slug_categoria)
            }
            break;
        }
      })

      setCategories(categories);
      setFilteredCarsPromotion(response)
      setLoading(false)
      setLoadingFilter(false)
    })
  }, [])

  useEffect(() => {
    if (width > 700) {
      setIsJourneyMobile(false)
    } else {
      setIsJourneyMobile(true)
    }
  },[width])

  const handleSignCar = (
    veiculo_id?: string, locadora_id?: number, categoria_id?: number, name_categoria?:string) => {

    const paramsToRedirect: any = {
      vid: veiculo_id !== undefined ? veiculo_id : null,
      lid: veiculo_id !== undefined ? locadora_id : null,
      cid: veiculo_id !== undefined ? categoria_id : null,
      cname: veiculo_id !== undefined ? name_categoria : null,
      utm_source: urlParams.utmSource,
      utm_medium: urlParams.utmMedium,
      utm_campaign: urlParams.utmCampaign,
      utm_convertion: urlParams.utmConvertion
    }

    let queryString = '';

    for (const key in paramsToRedirect) {
        if (paramsToRedirect[key] !== null) {
            queryString += `${key}=${paramsToRedirect[key]}&`;
        }
    }

    if (queryString.length > 0) {
        queryString = queryString.slice(0, -1);
    }
    const urlWithParams = queryString.length > 0 ? `/sign-car/cadastro?${queryString}` : `/sign-car/cadastro/`;
    window.location.href = urlWithParams;
  }

  const handleGetCategoryPromotions = (slug: string) => {
    setLoadingFilter(true);
    getCategoriesPromotionCars(slug).then((response) => {
      setFilteredCarsPromotion(response);
    }).finally(() => {
      setLoadingFilter(false);
    })
  }

  let testimonials: TestimonialCarousel[] = [{
    testimonial: "Assinei por causa da praticidade e da economia. Estou super satisfeita com o atendimento do Tiago, ele foi proativo e não desistiu do meu processo até a aprovação  da assinatura. Passei por outras empresas e nenhuma, mas nenhuma fez por mim o que ele fez. Parabéns pra ele e para toda a equipe que se empenhou na aprovação de crédito.",
    clientName: "Sandra de Abreu",
    clientCity: "Vila Velha/ES",
    carModel: "Jeep Renegade"
  },
  {
    testimonial: "Parabéns pelo excelente nível de atendimento desde o primeiro contato até hoje. Poucas vezes eu vi um atendimento de serviço com tanta eficiência e cuidado. Essa experiência certamente eu vou recomendar pra muita gente",
    clientName: "Edivaldo",
    clientCity: "Americana/SP",
    carModel: "Nivus Comfortline"
  },
  {
    testimonial: "Estou apaixonada na escolha. Carro zero pro valor acessível e preocupação só com combustível. O atendimento foi top, tiraram minhas dúvidas e tiveram muita paciência comigo. O melhor é que meu carro chegou em 2 dias",
    clientName: "Luciana Lima",
    clientCity: "Maceió/AL",
    carModel: "Argo Drive"
  },
  {
    testimonial: "O atendimento foi extremamente profissional, ético e o Expert tirou todas as minhas dúvidas. Essa postura fez toda a diferença para que eu fechasse a assinatura. Super indico!",
    clientName: "Raiana Cunha",
    clientCity: "Brasília/DF",
    carModel: "Creta 1.6 Action"
  }]

  if (loading) {
    return (
      <div className="loading-container mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <>
      <div className={width > 1200 ? "container" : ""}>
        <Row className="col-12 row-home" style={{ paddingTop: '25px', marginLeft: '0px' }}>
          <Row style={{ background: "linear-gradient(180deg, #fff 13.83%, #ececf823 80.65%)", paddingTop: '10px', paddingBottom: '10px', paddingRight: '0px',  marginLeft: '0px' }}>
            <Col xs={6}>
              <img style={{maxHeight: "70px"}} src={getUrlImgLogoDefault()} alt=""/>
              <span className="mt-4 mb-4 text-banner">Ter um <b>carro por assinatura</b> nunca foi tão fácil!</span>
              <Button className="btn-banner py-3" onClick={() => handleSignCar()}>Quero Assinar!</Button>
            </Col>
            <Col xs={6} className="banner-background"></Col>
          </Row>

          <Col md={12} className="mt-5">
            <h5>Como funciona o carro por <span className="text-primary"><b>assinatura?</b></span></h5>
          </Col>

          <Col md={12} className="mt-4 mb-4 text-center video-player">
            <iframe width="80%" height="700px" src="https://www.youtube.com/embed/iH0loAz4hD0" title="Vídeo BYeCar" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <p><b>*Não atendemos</b> motoristas de aplicativo</p>
          </Col>

          <Col md={12} className="mt-5 mb-4">
            <h5>Conheça nossos <span className="text-primary"><b>carros</b></span></h5>
            <Carousels variant="categories" data={categories} showDots={false} getCategoryPromotionCars={handleGetCategoryPromotions}/>
          </Col>

          <Col md={12} className="mt-1 mb-4">
            <h5 className="mb-4">&nbsp;</h5>

            {
              loadingFilter ? (
                <Row>
                  <Col>
                    <Card style={{ height: '40vh', border: 'none' }}>
                      <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder xs={12} style={{ height: '20vh' }} />
                        </Placeholder>
                        <Placeholder as={Card.Text} animation="glow">
                          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                          <Placeholder xs={6} /> <Placeholder xs={8} />
                        </Placeholder>
                      </Card.Body>
                    </Card>
                  </Col>

                  {
                    width > 800 &&
                    <>
                      <Col>
                        <Card style={{ height: '40vh', border: 'none' }}>
                          <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                              <Placeholder xs={12} style={{ height: '20vh' }} />
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                              <Placeholder xs={6} /> <Placeholder xs={8} />
                            </Placeholder>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col>
                        <Card style={{ height: '40vh', border: 'none' }}>
                          <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                              <Placeholder xs={12} style={{ height: '20vh' }} />
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                              <Placeholder xs={6} /> <Placeholder xs={8} />
                            </Placeholder>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  }

                </Row>
              ) : (
                <CarouselPromotion data={filteredCarsPromotion} handleSignCar={handleSignCar} />
              )
            }

          </Col>
          
          <Col md={12} className="mt-5 p-0">
            {
              isJourneyMobile ? 
              (<img width="100%" src={getUrlImgJourneyMobile()} alt="" />) :
              (<img width="100%" src={getUrlImgJourneyWeb()} alt="" />)
            }
          </Col>

          <Col md={12} className="mt-4">
            <h5>Quais são as vantagens em <span className="text-primary"><b>assinar</b></span> um carro?</h5>
            <Row className="mt-3 mb-3 d-flex justify-content-center">
              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Esqueça</b></span> a dor de cabeça de todo início de ano pagar seguro, IPVA e DPVAT</p>
              </Col>

              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Economize</b></span> até 20% em relação a compra à vista do carro 0km</p>
              </Col>

              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Invista</b></span> nos seus sonhos com o valor que seria gasto na compra do carro</p>
              </Col>

              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Ganhe</b></span> da inflação e do aumento do preço dos carros com mensalidade sem reajuste</p>
              </Col>

              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Não se preocupe</b></span> com revisões, sinistros e troca de pneus</p>
              </Col>

              <Col xs={2} md={1} className="d-flex justify-content-center double-check text-primary">
                <RiCheckDoubleLine/>
              </Col>
              <Col xs={10} md={11} className="p-0">
                <p><span className="text-primary"><b>Nunca mais pense</b></span> em depreciação e venda do seu carro</p>
              </Col>
            </Row>
          </Col>

          <Col md={12} className="mt-4 mb-4">
            <h5>O que nossos clientes acham da <span className="text-primary"><b>assinatura!</b></span></h5>
            <Carousels variant="testimonial" data={testimonials}/>
          </Col>

          <Col md={12} className="mt-5">
            <h5>Ainda com <span className="text-primary"><b>dúvidas?!</b></span></h5>
            <Accordion className="mt-4 collapse-home">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Como funciona o carro por assinatura?</Accordion.Header>
                <Accordion.Body>
                  É bem simples. Você escolhe um modelo (temos mais de 60 opções), decide o tempo em que deseja ficar com o carro (12 a 48 meses) e quantos KMs deseja rodar por mês (1.000 a 3.000). Após isso a Locadora compra o carro 0KM na Montadora na cor que você escolheu e te entrega pronto para uso, já com documentação, manutenção preventiva e proteção inclusas no contrato, só precisa se preocupar com as mensalidades e com o abastecimento do carro.<br/>
                  Ao término do contrato, você tem a opção de comprar o carro pelo preço da tabela FIPE (depende da Locadora), de fazer a renovação por outro carro 0KM ou de simplesmente encerrar contrato e devolver o carro.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. O que está incluso na mensalidade?</Accordion.Header>
                <Accordion.Body>
                  Isso vai depender de cada empresa, algumas tem serviços adicionais como assistência residencial, manutenção corretiva e carro reserva. De modo geral todas tem proteção, assistência 24h, manutenção preventiva, documentação do carro; Substituição dos pneus e a opção de incluir motoristas adicionais.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. Existe algum custo além da mensalidade?</Accordion.Header>
                <Accordion.Body>
                  Sim, todas as empresas cobram as multas de maneira à parte da mensalidade e a coparticipação da franquia em caso de sinistro. Além disso, a grande maioria também cobra por avarias ou necessidade de manutenções corretivas ao carro.<br/>
                  Mas fique tranquilo, isso acontece em casos excepcionais!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>4. Qual a cobertura da proteção (seguro)?</Accordion.Header>
                <Accordion.Body>
                  Isso também depende da empresa, algumas incluem coberturas para danos materiais, corporais e até morais à terceiros. De maneira geral, todas empresas cobrem o valor integral do carro mediante pagamento da franquia em casos de roubo, furto, incêndios, perda total e danos causados ao veículo.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>5. Qualquer pessoa pode contratar?</Accordion.Header>
                <Accordion.Body>
                  Sim, desde que tenha acima de 21 anos, seja habilitado e tenha condições financeiras de arcar com as mensalidades mediante análise de crédito. Lembrando que a assinatura NÃO é permitida para motoristas de aplicativos.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>6. Existe multa caso o carro seja devolvido antes do término do contrato?</Accordion.Header>
                <Accordion.Body>
                  Sim, semelhante ao aluguel de imóvel existe a multa rescisória que varia de valor dependendo da empresa contratada.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>7. O que acontece se não for utlizado todos os KMs no mês? E se ultrapassar a quantidade de KM?</Accordion.Header>
                <Accordion.Body>
                  A quilometragem é acumulativa, ou seja, fica a critério do cliente definir como os KMs serão distribuídos ao longo do contrato. Caso a quantidade de KMs contratada seja ultrapassada ao término do contrato será cobrado um valor de KM excedente e em algumas empresas até multa.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>8. É possível trocar de carro durante o contrato?</Accordion.Header>
                <Accordion.Body>
                  Infelizmente não, é possível fazer a renovação ao término do contrato e pegar outro carro 0KM.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>9. Posso comprar o carro no término do contrato?</Accordion.Header>
                <Accordion.Body>
                Algumas empresas permitem a compra do carro pelo valor da tabela FIPE vigente no período. Mas sinceramente, não faz sentido assinar e depois comprar.<br/>
                Por exemplo:<br/>
                O Argo Drive 1.0 custa R$ 65 mil, desconsiderando os demais custos como documentação, seguro e manutenção.
                Para um contrato de assinatura de 48 meses o valor pago durante todo o contrato será próximo de R$ 58 mil, se o cliente comprar o carro pelo preço da tabela FIPE no final dos 48 meses pagará aproximadamente R$ 40 mil no carro, sendo assim o custo do Argo seria de R$ 98 mil (R$ 58 mil da assinatura + R$ 40 mil da compra do usado), muito mais caro do que comprar o carro inicialmente ou somente assinar.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col md={12} className="mt-5 mb-5">
            <Row>
              <Col xs={12} md={6} className="order-2 order-md-1 mt-3 text-center">
                <h5>Bora assinar seu <span className="text-primary"><b>carro?</b></span></h5>
                <Button className="mt-4 py-3" onClick={() => handleSignCar()}>Quero Assinar!</Button>
              </Col>
              <Col xs={12} md={6} className="order-1 order-md-2 mt-3">
                <img width="100%" height="100%" src={carsFooter} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Footer/>
    </>
  );
};
import { Col } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

// Components
import { CustomDot } from './components/CustomDot';
import { CategoriesType } from './components/CategoriesType';
import { TestimonialType } from './components/TestimonialType';
import { useState } from 'react';

import * as themes from '../../theme/schema.json';
type ObjectKey = keyof typeof themes.data;
const currentTheme = process.env.REACT_APP_NAME! as ObjectKey;
const colors = themes.data[currentTheme].colors;

interface CarouselVariantProps {
  variant: "categories" | "testimonial",
  data: any,
  showDots?: boolean,
  getCategoryPromotionCars?: (slug: string) => void
}

const CustomRightArrow = (arrowProps: any) => {
  const { carouselState, children, ...restArrowProps } = arrowProps;
  return (
    <Col {...restArrowProps} style={{ position: 'absolute', color: colors.mainColors.primary, right: '10px', fontSize: '30px' }}>
      <FaChevronRight />
    </Col>
  );
};

const CustomLeftArrow = (arrowProps: any) => {
  const { carouselState, children, ...restArrowProps } = arrowProps;
  return (
    <Col {...restArrowProps} style={{ position: 'absolute', color: colors.mainColors.primary, fontSize: '30px' }}>
      <FaChevronLeft />
    </Col>
  );
};

export const Carousels = ({variant, data, showDots = true, getCategoryPromotionCars}: CarouselVariantProps) => {
  let itemsToShowDesktop = variant === 'testimonial' ? 4 : 5;
  let itemsToShowMobile = variant === 'categories' ? 2 : 1;
  const [categorySelected, setCategorySelected] = useState('');

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itemsToShowDesktop,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 560, min: 0 },
      items: itemsToShowMobile,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    }
  };
  
  return (
    <Carousel
      responsive={responsive}
      infinite={variant === 'categories' ? false : true}
      autoPlay={variant === 'categories' ? false : true}
      showDots={showDots ? true : false}
      customDot={showDots ? <CustomDot /> : <></>}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      removeArrowOnDeviceType={variant === 'categories' ? ["tablet", "mobile", "desktop"] : ["tablet", "mobile"]}
      partialVisible={variant === 'categories' ? true : false}
    >
      {
        variant === 'categories' && 
        data?.map((item: any, index: number) => (
          <CategoriesType 
            key={index} 
            categoryName={item.nome} 
            categoryImage={item.image} 
            slug={item.slug}
            categorySelected={categorySelected}
            setCategorySelected={setCategorySelected}
            handleSlug={getCategoryPromotionCars}/>
        ))
      }

      {
        variant === 'testimonial' && 
        data?.map((item: any, index: number) => (
          <TestimonialType key={index} testimonial={item.testimonial} clientName={item.clientName} clientCity={item.clientCity} carModel={item.carModel}/>
        ))
      }
    </Carousel>
  )
}